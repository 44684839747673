import React from "react";
import { AppContext } from "../../../../context";
import { Card, Col, Row, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Request from "../../../../request";
import { Bar, PolarArea } from "react-chartjs-2";
import Select from "react-select";

class DashBoard extends React.Component {
  static contextType = AppContext;

  state = {
    countEncaminhada: [], // Inicialize como array vazio
    countRecebida: [],
    countEmDiligencia: [],
    countRegistradaCidades: [],
    natureza: [],
    cidade:[],
    bairro:[],
    setores: [],
    orgaos: [],
    denuncias: [],
    historicos: [],
    selectedOrgao: null,
    selectedSetor: null,
    dataInicial: null,
    dataFinal: null,
  };
  

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  fetchData = async () => {
    let { dataInicial, dataFinal, selectedOrgao, selectedSetor } = this.state;
    let baseUrl = this.context.config.BACKEND_URL;

    // Validação da Data
    if (dataInicial && dataFinal && dataInicial > dataFinal) {
        return this.context.addToast({
            titulo: "Erro",
            conteudo: "Data inicial não pode ser maior que a data final.",
        });
    }

    // Construção das URLs
    let dashboardParams = [
        dataInicial || "*",
        dataFinal || "*",
        selectedOrgao || "*",
        selectedSetor || "*"
    ];
    let scorecardParams = [
        dataInicial || "*",
        dataFinal || "*",
        selectedOrgao || "*",
        selectedSetor || "*"
    ];

    let dashboardUrl = `${baseUrl}/denuncia/dashboard/${dashboardParams.join("/")}`;
    let scorecardDenunciaUrl = `${baseUrl}/denuncia/scorecardDenuncia/${scorecardParams.join("/")}`;
    let scorecardHistoricoUrl = `${baseUrl}/denuncia/scorecardHistorico/${scorecardParams.join("/")}`;

    try {
        // Executar ambas as requisições em paralelo
        let [dashboardRes, scorecardDenunciaRes, scorecardHistoricoRes] = await Promise.all([
            Request("GET", dashboardUrl, this.context.token),
            Request("GET", scorecardDenunciaUrl, this.context.token),
            Request("GET", scorecardHistoricoUrl, this.context.token)
        ]);

        this.setState({
            // Dados do dashboard
            countEncaminhada: dashboardRes.body.countEncaminhada,
            countRecebida: dashboardRes.body.countRecebida,
            countEmDiligencia: dashboardRes.body.countEmDiligencia,
            countFinalizada: dashboardRes.body.countFinalizada,
            natureza: dashboardRes.body.natureza,
            cidade: dashboardRes.body.cidade,
            bairro: dashboardRes.body.bairro,

            // Dados do scorecard
            denuncias: scorecardDenunciaRes.body.denunciaPrazos,
            historicos: scorecardHistoricoRes.body.denunciaPrazos,
        });
    } catch (err) {
        this.context.addToast({
            titulo: "Erro",
            conteudo: "Falha na recuperação dos dados: " + err.toString(),
        });
    }
};


  handleFilterButtonClick = () => {
    this.fetchData();
  };

  handleResetFilters = () => {
    this.setState(
      {
        dataInicial: null,
        dataFinal: null,
        selectedOrgao: null,
        selectedSetor: null,
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleOrgaoChange = (selectedOption) => {
    this.setState({ selectedOrgao: selectedOption.value });
  };

  handleSetorChange = (selectedOption) => {
    this.setState({ selectedSetor: selectedOption.value });
  };

  render() {

    const labelsCountRegCid = this.state.cidade?.map(
      (item) => item.Cidade?.nome
    );
    const dataCountRegCid = this.state.cidade?.map(
      (item) => item.count
    );
    const labelsCountNat = this.state.natureza?.map(
      (item) => item.Natureza?.nome
    );
    const dataCountNat = this.state.natureza.map((item) => item.count);
  
    const labelsBairro = this.state.bairro?.map(
      (item) => item.Bairro?.nome
    );
    const dataBairro = this.state.bairro?.map(
      (item) => item.count
    );

    const colors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
    ];

    const borderColor = [
      "rgba(255, 99, 132, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 206, 86, 1)",
      "rgba(75, 192, 192, 1)",
      "rgba(153, 102, 255, 1)",
      "rgba(255, 159, 64, 1)",
    ];

    const chartDataRegCid = {
      labels: labelsCountRegCid,
      datasets: [
        {
          label: "Total Registrada por Cidade",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) =>
            color.replace("0.2", "0.4")
          ),
          hoverBorderColor: borderColor,
          data: dataCountRegCid,
        },
      ],
    };

    const chartDataNat = {
      labels: labelsCountNat,
      datasets: [
        {
          label: "Top 10 Naturezas Mais Registradas",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) =>
            color.replace("0.2", "0.4")
          ),
          hoverBorderColor: borderColor,
          data: dataCountNat,
        },
      ],
    };
    const chartDataBairro = {
      labels: labelsBairro,
      datasets: [
        {
          label: "Top 10 Bairros Mais Registrados",
          backgroundColor: colors,
          borderColor: borderColor,
          borderWidth: 1,
          hoverBackgroundColor: colors.map((color) =>
            color.replace("0.2", "0.4")
          ),
          hoverBorderColor: borderColor,
          data: dataBairro,
        },
      ],
    };

    const { setores } = this.state;
    const { orgaos } = this.state;

    return (
      <>
        <Row className="mt-2">
          <Col md={6}>
            <Form.Label>Órgão</Form.Label>
            <Select
              name="OrgaoId"
              noOptionsMessage={() => "Nada encontrado."}
              placeholder="Pesquisar..."
              menuPortalTarget={document.body}
              menuShouldBlockScroll={true}
              options={orgaos}
              value={
                orgaos.find(
                  (option) => option.value === this.state.selectedOrgao
                ) || null
              } // Define o valor para null quando resetado
              onChange={this.handleOrgaoChange}
            />
          </Col>
          <Col md={6}>
            <Form.Label>Setor</Form.Label>
            <Select
              name="SetorId"
              noOptionsMessage={() => "Nada encontrado."}
              placeholder="Pesquisar..."
              menuPortalTarget={document.body}
              menuShouldBlockScroll={true}
              options={setores.filter(
                (setor) => setor.OrgaoId === this.state.selectedOrgao
              )}
              value={
                setores.find(
                  (option) => option.value === this.state.selectedSetor
                ) || null
              } // Define o valor para null quando resetado
              onChange={this.handleSetorChange}
            />
          </Col>
          <Col className="my-2 d-flex align-items-center" md={4} >
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            <span style={{ whiteSpace: "nowrap" }}>Data Inicial</span>
            <input
              type="datetime-local"
              name="dataInicial"
              className="form-control ml-2"
              value={this.state.dataInicial || ""} // Define como string vazia quando resetado
              onChange={this.handleInputChange}
            />
          </Col>
          <Col className="my-2 d-flex align-items-center" md={4} >
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            <span style={{ whiteSpace: "nowrap" }}>Data Final</span>
            <input
              type="datetime-local"
              name="dataFinal"
              className="form-control ml-2"
              value={this.state.dataFinal || ""} // Define como string vazia quando resetado
              onChange={this.handleInputChange}
            />
          </Col>
          <Col
            className="w-100 my-2 d-flex justify-content-between"
            md={4}
          >
            <Button
              variant="primary"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleFilterButtonClick}
            >
              <FontAwesomeIcon icon={faSearch} className="mr-2" />
              Filtrar
            </Button>
            <Button
              variant="danger"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleResetFilters}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
              Limpar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#1E90FF" }}
              >
                <Card.Header>Encaminhadas Atualmente</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.countEncaminhada?? 0}</h1>
                </Card.Body>
              </Card>
   
          </Col>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#228B22" }}
              >
                <Card.Header>Recebidas</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.countRecebida??0}</h1>
                </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#2F4F4F" }}
              >
                <Card.Header>Em Diligencia</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.countEmDiligencia??0}</h1>
                </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#778899" }}
              >
                <Card.Header>Finalizadas</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.countFinalizada??0}</h1>
                </Card.Body>
              </Card>
          </Col>
          </Row>
          <Row>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#228B22" }}
              >
                <Card.Header>No Prazo</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.denuncias.noPrazo}</h1>
                </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#ffc107" }}
              >
                <Card.Header>Prorrogada</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.denuncias.prorrogada}</h1>
                </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#DC3545" }}
              >
                <Card.Header>Atrasada</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.denuncias.atrasada}</h1>
                </Card.Body>
              </Card>
          </Col>
          </Row>
          <Row>
          <Col>
              <Card 
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#6495ED"}}
              >
                <Card.Header>Encaminhadas</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.historicos.enviadas}</h1>
                </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#228B22" }}
              >
                <Card.Header>Finalizadas ou no Prazo</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.historicos.finalizadasNoPrazo}</h1>
                </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#FF0000" }}
              >
                <Card.Header>Não Finalizadas</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>{this.state.historicos.naoFinalizadasForaDoPraso}</h1>
                </Card.Body>
              </Card>
          </Col>
          <Col>
              <Card
                className="text-white mb-3"
                style={{ height: "150px", backgroundColor: "#4B0082" }}
              >
                <Card.Header>Resolutividade</Card.Header>
                <Card.Body className="d-flex align-items-end justify-content-end">
                  <h1>
                  {this.state.historicos.finalizadasNoPrazo > 0
                    ? `${((this.state.historicos.finalizadasNoPrazo / this.state.historicos.enviadas) * 100).toFixed(2)}%`
                    : "0%"}
                  </h1>
                </Card.Body>
              </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <Bar
              data={chartDataRegCid}
              width={100}
              height={200}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="border">
            <PolarArea
              data={chartDataNat}
              width={100}
              height={600}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
          <Col className="border">
            <PolarArea
              data={chartDataBairro}
              width={100}
              height={600}
              options={{
                maintainAspectRatio: false,
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default DashBoard;