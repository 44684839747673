import React from 'react';
import RelatorioForm from '../relatorios/RelatorioForm';
import ModelList from '../ModelList';
import { AppContext } from '../../../context';
import RelatorioView from '../relatorios/RelatorioView';

class RelatorioList extends React.Component {   

    static contextType = AppContext;
        
    onAction(action, update, defaultHandler){ 
        switch (action.name){
            case 'result':                  
                this.context.openModal({ titulo: "Resultado", conteudo: <RelatorioView RelatorioId={action.id} onSave={() => update()}/>, size: "xl" });
                break;
            case 'print':                  
                this.context.setContent("RelatorioPrint", {RelatorioId: action.id});
                break;
            default:
                defaultHandler();
                break;                
        }
    }

    render(){
        return (
            <ModelList 
                url={this.context.config.BACKEND_URL + '/relatorioModulo'}
                title="Lista de Relatórios"
                modelName="relatorio"
                modelIcon="faChalkboardTeacher"
                modelForm={RelatorioForm}
                modalSize="xl"
                onAction={(action, update, defaultHandler) => this.onAction(action, update, defaultHandler)}
                useAdd={this.context.usuario.Permissoes.some(p => p.nome === 'relatorios_relatorio_edit')}
            />
        );
    }
}

export default RelatorioList;