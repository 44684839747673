import React from "react";
import { AppContext } from "../../../context";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Button,
  Col,
  Form as BSForm,
  Row,
  Tab,
  Tabs,
  FormGroup,
  Table,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faPlus,
  faSave,
  faTimes,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

class RelatorioGfluForm extends React.Component {
  static contextType = AppContext;

  state = {
    cidades: [],
    embarcacoes: [],
    showModal: false,
    success: false,
  };

  save(values, callback) {
    const method = this.props.relatorio.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/grupamentoFluvial/relatorio" +
      (this.props.relatorio.id ? "/" + this.props.relatorio.id : "");
    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        this.context.setContent("RelatorioGfluList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/grupamentoFluvial/embarcacao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ embarcacoes: options.body }));
      })
      .catch(errorHandler);
  }

  render() {
    const cidade_origem = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];

    const exito = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];

    const tipoAtividade = [
      { value: "Abordagens a Embarcação", label: "Abordagens em Embarcação" },
      { value: "Abordagem de Pessoas", label: "Abordagem de Pessoas" },
      { value: "Abordagens em Estabelecimentos", label: "Abordagens em Estabelecimentos" },
      { value: "Abordagens Fiscais", label: "Abordagens Fiscais" },
      { value: "Advertência", label: "Advertência" },
      { value: "Apoio a outro órgão", label: "Apoio a outro órgão" },
      { value: "Ação Social", label: "Ação Social" },
      { value: "Avaliação Técnica (CBM-PA)", label: "Avaliação Técnica (CBM-PA)" },
      { value: "Averiguação de Informação", label: "Averiguação de Informação" },
      { value: "Atendimento Pré-Hospitalar - APH", label: "Atendimento Pré-Hospitalar - APH" },
      { value: "Atendimento de Ocorrência", label: "Atendimento de Ocorrência" },
      { value: "Auto de Apreensão", label: "Auto de Apreensão" },
      { value: "Auto de Destruição", label: "Auto de Destruição" },
      { value: "Auto de Infração", label: "Auto de Infração" },
      { value: "Auto de Inutilização", label: "Auto de Inutilização" },
      { value: "Boletim de Ocorrências Policiais - Registrados", label: "Boletim de Ocorrências Policiais - Registrados" },
      { value: "Busca e Apreensão", label: "Busca e Apreensão" },
      { value: "Combate à Propagação de Fogo", label: "Combate à Propagação de Fogo" },
      { value: "Deslocamento com Feridos", label: "Deslocamento com Feridos" },
      { value: "Diligência", label: "Diligência" },
      { value: "Disque Denúncia", label: "Disque Denúncia" },
      { value: "Documentos Sanitários Fiscalizados", label: "Documentos Sanitários Fiscalizados" },
      { value: "Fiscalizações Sanitárias Realizadas", label: "Fiscalizações Sanitárias Realizadas" },
      { value: "Inquérito Policial por Portaria", label: "Inquérito Policial por Portaria" },
      { value: "Inquérito por Flagrante (Prisões)", label: "Inquérito por Flagrante (Prisões)" },
      { value: "Inspeção de Equipamento de Salvatagem", label: "Inspeção de Equipamento de Salvatagem" },
      { value: "Intimação", label: "Intimação" },
      { value: "Levantamento de Informações", label: "Levantamento de Informações" },
      { value: "Mandado de Busca e Apreensão", label: "Mandado de Busca e Apreensão" },
      { value: "Mandado de Medida Protetiva", label: "Mandado de Medida Protetiva" },
      { value: "Mandado de Prisão", label: "Mandado de Prisão" },
      { value: "Notas Fiscais Protocoladas/Fiscalizadas", label: "Notas Fiscais Protocoladas/Fiscalizadas" },
      { value: "Operação Extraordinária", label: "Operação Extraordinária" },
      { value: "Recuperação de Objetos", label: "Recuperação de Objetos" },
      { value: "Remoção Cadavérica", label: "Remoção Cadavérica" },
      { value: "Resgate de Pessoas/Equipamento", label: "Resgate de Pessoas/Equipamento" },
      { value: "Ronda", label: "Ronda" },
      { value: "Termo Circunstanciado de Ocorrência - TCO", label: "Termo Circunstanciado de Ocorrência - TCO" },
      { value: "Transferência de Preso", label: "Transferência de Preso" },
      { value: "Visitação à Comunidade", label: "Visitação à Comunidade" },
    ];

    const unidadeEntorpecente = [
      { value: "Gramas", label: "Gramas" },
      { value: "Quilogramas", label: "Quilogramas" },
      { value: "Toneladas", label: "Toneladas" },
      { value: "Tabletes", label: "Tabletes" },
      { value: "Frascos ou Ampolas", label: "Frascos ou Ampolas" },
      { value: "Comprimidos", label: "Comprimidos" },
      { value: "Litros", label: "Litros" },
      { value: "Mililitros", label: "Mililitros" },
      { value: "Unidade(s)", label: "Unidade(s)" },
    ];

    const naturezaAtividade = [
      { value: "Roubo", label: "Roubo" },
      { value: "Homicídio", label: "Homicídio" },
      { value: "Feminicídio", label: "Feminicídio" },
      { value: "Outro", label: "Outro" },
    ];

    const optionTipoPrisao = [
      { value: "PRISÃO EM FLAGRANTE", label: "PRISÃO EM FLAGRANTE" },
      { value: "PRISÃO PREVENTIVA", label: "PRISÃO PREVENTIVA" },
      { value: "PRISÃO TEMPORÁRIA", label: "PRISÃO TEMPORÁRIA" },
      { value: "APREENSÃO", label: "APREENSÃO" },
      { value: "RECAPTURADO", label: "RECAPTURADO" },
    ];

    const optionsCoisa = [
      { value: "ARMA DE FOGO", label: "ARMA DE FOGO" },
      { value: "MUNIÇÕES", label: "MUNIÇÕES" },
      { value: "ARMA BRANCA", label: "ARMA BRANCA" },
      { value: "ENTORPECENTES", label: "ENTORPECENTES" },
      { value: "VEÍCULOS", label: "VEÍCULOS" },
      { value: "ANIMAIS", label: "ANIMAIS" },
      { value: "APARELHOS ELETRÔNICOS", label: "APARELHOS ELETRÔNICOS" },
      { value: "DINHEIRO", label: "DINHEIRO" },
      { value: "OUTRAS APREENSÕES", label: "OUTRAS APREENSÕES" },
    ];

    const optionsCalibre = [
      { value: "22", label: "22" },
      { value: "32", label: "32" },
      { value: "38", label: "38" },
      { value: "357", label: "357" },
      { value: "765", label: "765" },
      { value: "380", label: "380" },
      { value: ".40", label: ".40" },
      { value: "6.35", label: "6.35" },
      { value: "45", label: "45" },
      { value: "9", label: "9" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const getOptionsBasedOnTipoCoisa = (tipoCoisa) => {
      switch (tipoCoisa) {
        case "ARMA DE FOGO":
          return optionsTipoArmaMunicao;
        case "MUNIÇÕES":
          return optionsTipoArmaMunicao;
        case "ENTORPECENTES":
          return optionsEntorpecentes;
        case "VEÍCULOS":
          return optionsVeiculos;
        case "APARELHOS ELETRÔNICOS":
          return optionsAparelhoEletronico;
        default:
          return [];
      }
    };

    const optionsTipoArmaMunicao = [
      { value: "REVÓLVER", label: "REVÓLVER" },
      { value: "PISTOLA", label: "PISTOLA" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsEntorpecentes = [
      { value: "MACONHA", label: "MACONHA" },
      { value: "COCAINA", label: "COCAINA" },
      { value: "PASTA BASE", label: "PASTA BASE" },
      { value: "CRACK", label: "CRACK" },
      { value: "OXI", label: "OXI" },
      { value: "LIMÃOZINHO", label: "LIMÃOZINHO" },
      { value: "LSD", label: "LSD" },
      { value: "ECSTASY", label: "ECSTASY" },
      { value: "OUTROS", label: "OUTROS" },
      { value: "SKANK", label: "SKANK" },
    ];

    const optionsVeiculos = [
      { value: "CARRO", label: "CARRO" },
      { value: "MOTO", label: "MOTO" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsAparelhoEletronico = [
      { value: "CELULAR", label: "CELULAR" },
      {
        value: "COMPUTADOR/NOTEBOOK/TABLET",
        label: "COMPUTADOR/NOTEBOOK/TABLET",
      },
      { value: "TELEVISÃO", label: "TELEVISÃO" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    return (
      <Formik
        initialValues={{
          ...this.props.relatorio,
          AtividadeGflus: (this.props.relatorio.AtividadeGflus ?? []).map(
            (atividade) => ({
              ...atividade,
            })
          ),

          PrisoesGflu: (this.props.relatorio.PrisoesGflu ?? []).map(
            (prisao) => ({
              ...prisao,
            })
          ),

          ApreensoesGflu: (this.props.relatorio.ApreensoesGflu ?? []).map(
            (apreensao) => ({
              ...apreensao,
            })
          ),
        }}
        validate={(values) => {
          const errors = {};

          if (!values.EmbarcacaoId) {
            errors.EmbarcacaoId = "Campo obrigatório";
          }

          if(!values.data){
            errors.data = "Campo obrigatório";
          }

          if(!values.horimetro){
            errors.horimetro = "Campo obrigatório";
          }

          if(!values.data_coleta_horimetro){
            errors.data_coleta_horimetro = "Campo obrigatório";
          }

          if(!values.cidade_origem){
            errors.cidade_origem = "Campo obrigatório";
          }

          if (values.AtividadeGflus.length > 0) {
            values.AtividadeGflus.forEach((atividade, index) => {
              if (!atividade.tipo) {
                errors[`AtividadeGflus[${index}].tipo`] = "Campo obrigatório";
              }
              // if (
              //   !atividade.numero_bapm &&
              //   atividade.tipo !== "Abordagem de Pessoas"
              // ) {
              //   errors[`AtividadeGflus[${index}].numero_bapm`] =
              //     "Campo obrigatório";
              // }

              if (atividade.tipo === "Ronda") {
                if (!atividade.horario_ronda) {
                  errors[`AtividadeGflus[${index}].horario_ronda`] =
                    "Campo obrigatório";
                }
              }

              if (!atividade.quantidade) {
                errors[`AtividadeGflus[${index}].quantidade`] =
                  "Campo obrigatório";
              }
            });
          }

          if (values.PrisoesGflu.length > 0) {
            values.PrisoesGflu.forEach((prisao, index) => {
              if (!prisao.tipo) {
                errors[`PrisoesGflu[${index}].tipo`] = "Campo obrigatório";
              }
              if (!prisao.nome_preso) {
                errors[`PrisoesGflu[${index}].nome_preso`] =
                  "Campo obrigatório";
              }
              if (!prisao.numero_boletim) {
                errors[`PrisoesGflu[${index}].numero_boletim`] =
                  "Campo obrigatório";
              }
            });
          }

          if (values.ApreensoesGflu.length > 0) {
            values.ApreensoesGflu.forEach((apreensao, index) => {
              if (!apreensao.coisa) {
                errors[`ApreensoesGflu[${index}].coisa`] = "Campo obrigatório";
              }

              if (!apreensao.numero_boletim) {
                errors[`ApreensoesGflu[${index}].numero_boletim`] =
                  "Campo obrigatório";
              }

              if (apreensao.coisa === "ARMA DE FOGO") {
                if (!apreensao.descricao) {
                  errors[`ApreensoesGflu[${index}].descricao`] =
                    "Campo obrigatório";
                }

                if (!apreensao.tem_rp) {
                  errors[`ApreensoesGflu[${index}].tem_rp`] =
                    "Campo obrigatório";
                }
              }

              if (apreensao.coisa === "ENTORPECENTES") {
                if (!apreensao.unidade) {
                  errors[`ApreensoesGflu[${index}].unidade`] =
                    "Campo obrigatório";
                }
              }
            });
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue, setValues }) => {
          return (
            <>
              <Form>
                <Tabs defaultActiveKey="relatorios">
                  <Tab
                    eventKey="relatorios"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faUser} />{" "}
                        <span className="d-none d-lg-inline">Relatório</span>
                      </div>
                    }
                  >
                    <Card className="mt-3">
                      <Card.Header>
                        <strong>Dados Básicos do Relatório</strong>
                      </Card.Header>
                      <Card.Body>
                        <Row className="mt-2">
                          <Col md={4}>
                            <BSForm.Label as="b">
                              Embarcação <span className="text-danger">*</span>
                            </BSForm.Label>
                            <ErrorMessage
                              name="EmbarcacaoId"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Select
                              name="EmbarcacaoId"
                              menuShouldBlockScroll={true}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              options={this.state.embarcacoes.map(
                                (embarcacao) => ({
                                  value: embarcacao.id,
                                  label: embarcacao.nome,
                                })
                              )}
                              value={this.state.embarcacoes
                                .map((embarcacao) => ({
                                  value: embarcacao.id,
                                  label: embarcacao.nome,
                                }))
                                .find(
                                  (option) =>
                                    option.value === values.EmbarcacaoId
                                )}
                              onChange={(selectedOption) => {
                                const selectedValue = selectedOption
                                  ? selectedOption.value
                                  : null;
                                setFieldValue("EmbarcacaoId", selectedValue);
                              }}
                            />
                          </Col>
                          <Col md={4}>
                            <BSForm.Label as="b">Período <span className="text-danger">*</span></BSForm.Label>
                            <ErrorMessage
                              name="data"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              name="data"
                              className="form-control"
                              type="month"
                              value={values.data ? values.data.slice(0, 7) : ""}
                              onChange={(e) =>
                                setFieldValue("data", e.target.value)
                              }
                            />
                          </Col>

                          <Col md={4}>
                            <BSForm.Label as="b">Horímetro <span className="text-danger">*</span></BSForm.Label>
                            <ErrorMessage
                              name="horimetro"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="number"
                              name="horimetro"
                              className="form-control"
                            />
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col lg={4}>
                            <BSForm.Label as="b">
                              Data do Horímetro <span className="text-danger">*</span>
                            </BSForm.Label>
                            <ErrorMessage
                              name="data_coleta_horimetro"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              type="date"
                              name="data_coleta_horimetro"
                              className="form-control"
                            />
                          </Col>
                          <Col md={4}>
                            <BSForm.Label as="b">
                              Cidade de Origem? <span className="text-danger">*</span>
                            </BSForm.Label>
                            <ErrorMessage
                              name="cidade_origem"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Select
                              name="cidade_origem"
                              menuShouldBlockScroll={true}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              options={cidade_origem}
                              value={cidade_origem.find(
                                (cidade) =>
                                  cidade.value === values.cidade_origem
                              )}
                              onChange={(selectedOption) => {
                                const selectedValue = selectedOption
                                  ? selectedOption.value
                                  : null;
                                setFieldValue("cidade_origem", selectedValue);
                              }}
                            />
                          </Col>
                          {values.cidade_origem === "Não" && (
                            <>
                              <Col md={4}>
                                <BSForm.Label as="b">Cidade</BSForm.Label>
                                <ErrorMessage
                                  name="CidadeId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="CidadeId"
                                  menuShouldBlockScroll={true}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  options={this.state.cidades}
                                  value={this.state.cidades.find(
                                    (option) => option.value === values.CidadeId
                                  )}
                                  onChange={(selectedOption) => {
                                    const selectedValue = selectedOption
                                      ? selectedOption.value
                                      : null;
                                    setFieldValue("CidadeId", selectedValue);
                                  }}
                                />
                              </Col>
                              <Col md={4}>
                                <BSForm.Label as="b">Motivo</BSForm.Label>
                                <ErrorMessage
                                  name="motivo"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Field
                                  type="text"
                                  name="motivo"
                                  className="form-control"
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Tab>
                  <Tab
                    eventKey="atividades"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faFile} />{" "}
                        <span className="d-none d-lg-inline">Atividades</span>
                      </div>
                    }
                  >
                    {values.AtividadeGflus?.length > 0 ? (
                      values.AtividadeGflus?.map((atividades, key) => (
                        <Table striped bordered hover key={key}>
                          <thead>
                            <tr>
                              <th>{`Atividade Número ${key + 1}`}</th>
                              <th
                                style={{
                                  width: "1%",
                                  textAlign: "center",
                                }}
                              >
                                Excluir
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Row className="mt-2">
                                  <Col lg={6}>
                                    <BSForm.Label as="b">
                                      Tipo de Atividade
                                      <span className="text-danger">*</span>
                                    </BSForm.Label>
                                    <ErrorMessage
                                      name={`AtividadeGflus[${key}].tipo`}
                                      component="span"
                                      className="text-danger small ml-2"
                                    />
                                    <Select
                                      name={`AtividadeGflus[${key}].tipo`}
                                      menuShouldBlockScroll={true}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      options={tipoAtividade}
                                      value={tipoAtividade.find(
                                        (tipo) => tipo.value === atividades.tipo
                                      )}
                                      onChange={(selectedOption) => {
                                        const selectedValue = selectedOption
                                          ? selectedOption.value
                                          : null;
                                        setFieldValue(
                                          `AtividadeGflus[${key}].tipo`,
                                          selectedValue
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col lg={6}>
                                    <BSForm.Label as="b">
                                      Quantidade
                                      <span className="text-danger">*</span>
                                    </BSForm.Label>
                                    <ErrorMessage
                                      name={`AtividadeGflus[${key}].quantidade`}
                                      component="span"
                                      className="text-danger small ml-2"
                                    />
                                    <Field
                                      type="number"
                                      name={`AtividadeGflus[${key}].quantidade`}
                                      className="form-control"
                                      onChange={(e) =>
                                        setFieldValue(
                                          `AtividadeGflus[${key}].quantidade`,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  {atividades.tipo === "Mandado de Prisão" ||
                                  atividades.tipo === "Busca e Apreensão" ? (
                                    <>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Êxito
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].exito`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Select
                                          name={`AtividadeGflus[${key}].exito`}
                                          menuShouldBlockScroll={true}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                          options={exito}
                                          value={exito.find(
                                            (exito) =>
                                              exito.value === atividades.exito
                                          )}
                                          onChange={(selectedOption) => {
                                            const selectedValue = selectedOption
                                              ? selectedOption.value
                                              : null;
                                            setFieldValue(
                                              `AtividadeGflus[${key}].exito`,
                                              selectedValue
                                            );
                                          }}
                                        />
                                      </Col>

                                      {/* <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Nº BAPM
                                        </BSForm.Label>
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].numero_bapm`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col> */}

                                      {atividades.exito === "Sim" && (
                                        <>
                                          <Col lg={6}>
                                            <BSForm.Label as="b">
                                              Local
                                            </BSForm.Label>
                                            <ErrorMessage
                                              name={`AtividadeGflus[${key}].local`}
                                              component="span"
                                              className="text-danger small ml-2"
                                            />
                                            <Field
                                              type="text"
                                              name={`AtividadeGflus[${key}].local`}
                                              className="form-control"
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `AtividadeGflus[${key}].local`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                          <Col lg={6}>
                                            <BSForm.Label as="b">
                                              Descrição
                                            </BSForm.Label>
                                            <ErrorMessage
                                              name={`AtividadeGflus[${key}].descricao`}
                                              component="span"
                                              className="text-danger small ml-2"
                                            />
                                            <Field
                                              type="text"
                                              name={`AtividadeGflus[${key}].descricao`}
                                              className="form-control"
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `AtividadeGflus[${key}].descricao`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                        </>
                                      )}
                                    </>
                                  ) : atividades.tipo === "Disque Denúncia" ||
                                    atividades.tipo === "Intimação" ||
                                    atividades.tipo === "Diligência" ||
                                    atividades.tipo === "Apoio a outro órgão" ||
                                    atividades.tipo === "Ação Social" ||
                                    atividades.tipo ===
                                      "Operação Extraordinária" ? (
                                    <>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Local
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].local`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].local`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].local`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Descrição
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].descricao`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].descricao`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].descricao`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      {/* <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Nº BAPM
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].numero_bapm`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col> */}
                                    </>
                                  ) : atividades.tipo === "Ocorrência" ? (
                                    <>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Local
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].local`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].local`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].local`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Descrição
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].descricao`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].descricao`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].descricao`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Natureza
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].natureza`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Select
                                          name={`AtividadeGflus[${key}].natureza`}
                                          menuShouldBlockScroll={true}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                          options={naturezaAtividade}
                                          value={naturezaAtividade.find(
                                            (natureza) =>
                                              natureza.value ===
                                              atividades.natureza
                                          )}
                                          onChange={(selectedOption) => {
                                            const selectedValue = selectedOption
                                              ? selectedOption.value
                                              : null;
                                            setFieldValue(
                                              `AtividadeGflus[${key}].natureza`,
                                              selectedValue
                                            );
                                          }}
                                        />
                                      </Col>
                                      {/* <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Nº BAPM
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].numero_bapm`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col> */}
                                    </>
                                  ) : atividades.tipo ===
                                    "Transferência de Preso" ? (
                                    <>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Descrição
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].descricao`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].descricao`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].descricao`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Cidade de Origem
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].OrigemId`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Select
                                          name={`AtividadeGflus[${key}].OrigemId`}
                                          menuShouldBlockScroll={true}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                          options={this.state.cidades}
                                          value={this.state.cidades.find(
                                            (option) =>
                                              option.value ===
                                              atividades.OrigemId
                                          )}
                                          onChange={(selectedOption) => {
                                            const selectedValue = selectedOption
                                              ? selectedOption.value
                                              : null;
                                            setFieldValue(
                                              `AtividadeGflus[${key}].OrigemId`,
                                              selectedValue
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Cidade de Destino
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].DestinoId`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Select
                                          name={`AtividadeGflus[${key}].DestinoId`}
                                          menuShouldBlockScroll={true}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                          options={this.state.cidades}
                                          value={this.state.cidades.find(
                                            (option) =>
                                              option.value ===
                                              atividades.DestinoId
                                          )}
                                          onChange={(selectedOption) => {
                                            const selectedValue = selectedOption
                                              ? selectedOption.value
                                              : null;
                                            setFieldValue(
                                              `AtividadeGflus[${key}].DestinoId`,
                                              selectedValue
                                            );
                                          }}
                                        />
                                      </Col>
                                      {/* <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Nº BAPM
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].numero_bapm`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col> */}
                                    </>
                                  ) : atividades.tipo ===
                                    "Abordagem a Embarcação" ? (
                                    <>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Local
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].local`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].local`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].local`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Descrição
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].descricao`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].descricao`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].descricao`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      {/* <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Nº BAPM
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].numero_bapm`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col> */}
                                    </>
                                  ) : atividades.tipo === "Ronda" ? (
                                    <>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Local
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].local`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].local`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].local`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Descrição
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].descricao`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].descricao`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].descricao`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Horário da Ronda
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].horario_ronda`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].horario_ronda`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].horario_ronda`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      {/* <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Nº BAPM
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].numero_bapm`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col> */}
                                    </>
                                  ) : atividades.tipo ===
                                    "Levantamento de Informações" ? (
                                    <>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Tipo de Levantamento Realizado
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].descricao`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].descricao`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].descricao`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      {/* <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Nº BAPM
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].numero_bapm`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col> */}
                                    </>
                                  ) : atividades.tipo ===
                                    "Recuparação de Objetos" ? (
                                    <>
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Descrição do Objeto
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].descricao`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].descricao`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].descricao`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                      {/* <Col lg={6}>
                                        <BSForm.Label as="b">
                                          Nº BAPM
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`AtividadeGflus[${key}].numero_bapm`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `AtividadeGflus[${key}].numero_bapm`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col> */}
                                    </>
                                  ) : null}
                                </Row>
                              </td>
                              <td className="text-center align-middle">
                                <Button
                                  size="sm"
                                  type="button"
                                  variant="danger"
                                  title="Excluir"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Deseja realmente excluir essa Atividade?"
                                      )
                                    ) {
                                      const updatedAtividades = [
                                        ...values.AtividadeGflus,
                                      ];
                                      updatedAtividades.splice(key, 1);
                                      setFieldValue(
                                        `AtividadeGflus`,
                                        updatedAtividades
                                      );
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ))
                    ) : (
                      <h5 className="text-center mt-3">
                        Nenhuma atividade criada
                      </h5>
                    )}
                    <Row className="mt-2">
                      <Col mb={3}>
                        <Button
                          type="button"
                          variant="secondary"
                          className="btn btn-warning my-3"
                          onClick={() => {
                            if (Array.isArray(values.AtividadeGflus)) {
                              setFieldValue(`AtividadeGflus`, [
                                ...values.AtividadeGflus,
                                {},
                              ]);
                            } else {
                              setFieldValue(`AtividadeGflus`, [{}]);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          &nbsp; Adicionar Atividade
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab
                    eventKey="prisoes"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faFile} />{" "}
                        <span className="d-none d-lg-inline">Prisões</span>
                      </div>
                    }
                  >
                    {values.PrisoesGflu?.length > 0 ? (
                      values.PrisoesGflu?.map((prisoes, prisoesIndex) => (
                        <Table striped bordered hover key={prisoesIndex}>
                          <thead>
                            <tr>
                              <th>{`Prisão Número ${prisoesIndex + 1}`}</th>
                              <th
                                style={{
                                  width: "1%",
                                  textAlign: "center",
                                }}
                              >
                                Excluir
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Row key={prisoesIndex} className="my=3">
                                  <Col>
                                    <BSForm.Label as="b">
                                      TIPO DE PRISÃO OU APREENSÃO
                                      <span className="text-danger">*</span>
                                    </BSForm.Label>
                                    <ErrorMessage
                                      name={`PrisoesGflu[${prisoesIndex}].tipo`}
                                      component="span"
                                      className="text-danger small ml-2"
                                    />
                                    <Select
                                      name={`PrisoesGflu[${prisoesIndex}].tipo`}
                                      menuShouldBlockScroll={true}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      options={optionTipoPrisao}
                                      value={optionTipoPrisao.find(
                                        (option) =>
                                          option.value === prisoes.tipo
                                      )}
                                      onChange={(selectedOption) => {
                                        const selectedValue = selectedOption
                                          ? selectedOption.value
                                          : null;
                                        setFieldValue(
                                          `PrisoesGflu[${prisoesIndex}].tipo`,
                                          selectedValue
                                        );
                                      }}
                                    />
                                  </Col>
                                  <Col>
                                    <BSForm.Label as="b">
                                      NÚMERO DO BOLETIM DE OCORRÊNCIA
                                      <span className="text-danger">*</span>
                                    </BSForm.Label>
                                    <ErrorMessage
                                      name={`PrisoesGflu[${prisoesIndex}].numero_boletim`}
                                      component="span"
                                      className="text-danger small ml-2"
                                    />
                                    <Field
                                      name={`PrisoesGflu[${prisoesIndex}].numero_boletim`}
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setFieldValue(
                                          `PrisoesGflu[${prisoesIndex}].numero_boletim`,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <BSForm.Label as="b">
                                      NOME COMPLETO DO PRESO
                                      <span className="text-danger">*</span>
                                    </BSForm.Label>
                                    <ErrorMessage
                                      name={`PrisoesGflu[${prisoesIndex}].nome_preso`}
                                      component="span"
                                      className="text-danger small ml-2"
                                    />
                                    <Field
                                      name={`PrisoesGflu[${prisoesIndex}].nome_preso`}
                                      type="text"
                                      className="form-control"
                                      onChange={(e) =>
                                        setFieldValue(
                                          `PrisoesGflu[${prisoesIndex}].nome_preso`,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </td>
                              <td className="text-center align-middle">
                                <Button
                                  size="sm"
                                  type="button"
                                  variant="danger"
                                  title="Excluir"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Deseja realmente excluir essa Prisão ?"
                                      )
                                    ) {
                                      const updatedPrisoes = [
                                        ...values.PrisoesGflu,
                                      ];
                                      updatedPrisoes.splice(prisoesIndex, 1);
                                      setFieldValue(
                                        `PrisoesGflu`,
                                        updatedPrisoes
                                      );
                                    }
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                  ></FontAwesomeIcon>
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ))
                    ) : (
                      <h5 className="text-center mt-3">
                        Nenhuma prisão criada
                      </h5>
                    )}
                    <Row className="mt-2">
                      <Col mb={3}>
                        <Button
                          type="button"
                          variant="secondary"
                          className="btn btn-warning my-3"
                          onClick={() => {
                            if (Array.isArray(values.PrisoesGflu)) {
                              setFieldValue(`PrisoesGflu`, [
                                ...values.PrisoesGflu,
                                {},
                              ]);
                            } else {
                              setFieldValue(`PrisoesGflu`, [{}]);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          &nbsp; Adicionar Prisão
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab
                    eventKey="apreensoes"
                    title={
                      <div>
                        <FontAwesomeIcon icon={faFile} />{" "}
                        <span className="d-none d-lg-inline">Apreensões</span>
                      </div>
                    }
                  >
                    {values.ApreensoesGflu?.length > 0 ? (
                      values.ApreensoesGflu?.map(
                        (apreensoes, apreensoesIndex) => (
                          <Table striped bordered hover key={apreensoesIndex}>
                            <thead>
                              <tr>
                                <th>{`Apreensão Número ${
                                  apreensoesIndex + 1
                                }`}</th>
                                <th
                                  style={{
                                    width: "1%",
                                    textAlign: "center",
                                  }}
                                >
                                  Excluir
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <Row key={apreensoesIndex} className="mt-2">
                                    <Col lg={6}>
                                      <BSForm.Label as="b">
                                        OBJETO APREENDIDO{" "}
                                        <span className="text-danger">*</span>
                                      </BSForm.Label>
                                      <ErrorMessage
                                        name={`ApreensoesGflu[${apreensoesIndex}].coisa`}
                                        component="span"
                                        className="text-danger small ml-2"
                                      />
                                      <Select
                                        name={`ApreensoesGflu[${apreensoesIndex}].coisa`}
                                        menuShouldBlockScroll={true}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        options={optionsCoisa}
                                        value={optionsCoisa.find(
                                          (option) =>
                                            option.value === apreensoes.coisa
                                        )}
                                        onChange={(selectedOption) => {
                                          const selectedValue = selectedOption
                                            ? selectedOption.value
                                            : null;
                                          setFieldValue(
                                            `ApreensoesGflu[${apreensoesIndex}.coisa]`,
                                            selectedValue
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col lg={6}>
                                      <BSForm.Label as="b">
                                        NÚMERO DO BOLETIM DE OCORRÊNCIA
                                        <span className="text-danger">*</span>
                                      </BSForm.Label>
                                      <ErrorMessage
                                        name={`ApreensoesGflu[${apreensoesIndex}].numero_boletim`}
                                        component="span"
                                        className="text-danger small ml-2"
                                      />
                                      <Field
                                        type="text"
                                        name={`ApreensoesGflu[${apreensoesIndex}].numero_boletim`}
                                        className="form-control"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `ApreensoesGflu[${apreensoesIndex}].numero_boletim`,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Col>
                                    {apreensoes.coisa === "ARMA DE FOGO" ||
                                    apreensoes.coisa === "MUNIÇÕES" ||
                                    apreensoes.coisa === "ENTORPECENTES" ||
                                    apreensoes.coisa === "VEÍCULOS" ||
                                    apreensoes.coisa ===
                                      "APARELHOS ELETRÔNICOS" ? (
                                      <>
                                        <Col lg={6}>
                                          <BSForm.Label as="b">
                                            TIPO DE OBJETO APREENDIDO{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`ApreensoesGflu[${apreensoesIndex}].tipo`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <Select
                                            name={`ApreensoesGflu[${apreensoesIndex}].tipo`}
                                            menuShouldBlockScroll={true}
                                            styles={{
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                            options={getOptionsBasedOnTipoCoisa(
                                              apreensoes.coisa
                                            )}
                                            value={getOptionsBasedOnTipoCoisa(
                                              apreensoes.coisa
                                            ).find(
                                              (option) =>
                                                option.value === apreensoes.tipo
                                            )}
                                            onChange={(selectedOption) => {
                                              const selectedValue =
                                                selectedOption
                                                  ? selectedOption.value
                                                  : null;
                                              setFieldValue(
                                                `ApreensoesGflu[${apreensoesIndex}].tipo`,
                                                selectedValue
                                              );
                                            }}
                                          />
                                        </Col>
                                      </>
                                    ) : null}
                                    {(apreensoes.coisa === "ARMA DE FOGO" ||
                                      apreensoes.coisa === "MUNIÇÕES") &&
                                    (apreensoes.tipo === "REVÓLVER" ||
                                      apreensoes.tipo === "PISTOLA") ? (
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          CALIBRE{" "}
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`ApreensoesGflu[${apreensoesIndex}].calibre`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Select
                                          name={`ApreensoesGflu[${apreensoesIndex}.calibre]`}
                                          menuShouldBlockScroll={true}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                          options={optionsCalibre}
                                          value={optionsCalibre.find(
                                            (option) =>
                                              option.value ===
                                              apreensoes.calibre
                                          )}
                                          onChange={(selectedOption) => {
                                            const selectedValue = selectedOption
                                              ? selectedOption.value
                                              : null;
                                            setFieldValue(
                                              `ApreensoesGflu[${apreensoesIndex}.calibre]`,
                                              selectedValue
                                            );
                                          }}
                                        />
                                      </Col>
                                    ) : null}
                                    {apreensoes.coisa !== "DINHEIRO" ? (
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          QUANTIDADE{" "}
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`ApreensoesGflu[${apreensoesIndex}].quantidade`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="number"
                                          name={`ApreensoesGflu[${apreensoesIndex}].quantidade`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `ApreensoesGflu[${apreensoesIndex}].quantidade`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                    ) : null}
                                    {apreensoes.coisa === "ENTORPECENTES" ? (
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          UNIDADE DE MEDIDA{" "}
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`ApreensoesGflu[${apreensoesIndex}].unidade`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Select
                                          name={`ApreensoesGflu[${apreensoesIndex}].unidade`}
                                          menuShouldBlockScroll={true}
                                          styles={{
                                            menuPortal: (base) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                          options={unidadeEntorpecente}
                                          value={unidadeEntorpecente.find(
                                            (option) =>
                                              option.value ===
                                              apreensoes.unidade
                                          )}
                                          onChange={(selectedOption) => {
                                            const selectedValue = selectedOption
                                              ? selectedOption.value
                                              : null;
                                            setFieldValue(
                                              `ApreensoesGflu[${apreensoesIndex}].unidade`,
                                              selectedValue
                                            );
                                          }}
                                        />
                                      </Col>
                                    ) : null}

                                    {apreensoes.coisa === "DINHEIRO" ? (
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          VALOR{" "}
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`ApreensoesGflu[${apreensoesIndex}].valor`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`ApreensoesGflu[${apreensoesIndex}].valor`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `ApreensoesGflu[${apreensoesIndex}].valor`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                    ) : null}

                                    {apreensoes.coisa === "ARMA DE FOGO" ? (
                                      <>
                                        <Col lg={6}>
                                          <BSForm.Label as="b">
                                            MARCA{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`ApreensoesGflu[${apreensoesIndex}].descricao`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <Field
                                            type="text"
                                            name={`ApreensoesGflu[${apreensoesIndex}].descricao`}
                                            className="form-control"
                                            onChange={(e) =>
                                              setFieldValue(
                                                `ApreensoesGflu[${apreensoesIndex}].descricao`,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>

                                        <Col lg={6}>
                                          <BSForm.Label as="b">
                                            A ARMA POSSUI RP?{" "}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </BSForm.Label>
                                          <Field
                                            as="select"
                                            className="form-control"
                                            onChange={(e) =>
                                              setFieldValue(
                                                `ApreensoesGflu[${apreensoesIndex}].tem_rp`,
                                                e.target.value
                                              )
                                            }
                                            name={`ApreensoesGflu[${apreensoesIndex}].tem_rp`}
                                          >
                                            <option value="">Selecione</option>
                                            <option value="Sim">Sim</option>
                                            <option value="Não">Não</option>
                                          </Field>
                                        </Col>

                                        {values.ApreensoesGflu[apreensoesIndex]
                                          .tem_rp === "Sim" && (
                                          <Col lg={6}>
                                            <BSForm.Label as="b">
                                              NÚMERO DO RP{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </BSForm.Label>
                                            <ErrorMessage
                                              name={`ApreensoesGflu[${apreensoesIndex}].rp`}
                                              component="span"
                                              className="text-danger small ml-2"
                                            />
                                            <Field
                                              type="text"
                                              name={`ApreensoesGflu[${apreensoesIndex}].rp`}
                                              className="form-control"
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `ApreensoesGflu[${apreensoesIndex}].rp`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                        )}
                                      </>
                                    ) : null}

                                    {apreensoes.coisa !== "DINHEIRO" &&
                                    (apreensoes.coisa === "ANIMAIS" ||
                                      apreensoes.coisa ===
                                        "OUTRAS APREENSÕES" ||
                                      apreensoes.calibre === "OUTROS" ||
                                      apreensoes.coisa ===
                                        "APARELHOS ELETRÔNICOS" ||
                                      apreensoes.tipo === "OUTROS" ||
                                      apreensoes.coisa === "ARMA BRANCA") ? (
                                      <Col lg={6}>
                                        <BSForm.Label as="b">
                                          DESCRIÇÃO{" "}
                                          <span className="text-danger">*</span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`ApreensoesGflu[${apreensoesIndex}].descricao`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <Field
                                          type="text"
                                          name={`ApreensoesGflu[${apreensoesIndex}].descricao`}
                                          className="form-control"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `ApreensoesGflu[${apreensoesIndex}].descricao`,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Col>
                                    ) : null}
                                  </Row>
                                </td>
                                <td className="text-center align-middle">
                                  <Button
                                    size="sm"
                                    type="button"
                                    variant="danger"
                                    title="Excluir"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Deseja realmente excluir essa Apreensão ? "
                                        )
                                      ) {
                                        const updatedApreensoes = [
                                          ...values.ApreensoesGflu,
                                        ];
                                        updatedApreensoes.splice(
                                          apreensoesIndex,
                                          1
                                        );
                                        setFieldValue(
                                          `ApreensoesGflu`,
                                          updatedApreensoes
                                        );
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                    ></FontAwesomeIcon>
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )
                      )
                    ) : (
                      <h5 className="text-center mt-3">
                        Nenhuma apreensão criada
                      </h5>
                    )}
                    <Row className="mt-2">
                      <Col mb={3}>
                        <Button
                          type="button"
                          variant="secondary"
                          className="btn btn-warning my-3"
                          onClick={() => {
                            if (Array.isArray(values.ApreensoesGflu)) {
                              setFieldValue(`ApreensoesGflu`, [
                                ...values.ApreensoesGflu,
                                {},
                              ]);
                            } else {
                              setFieldValue(`ApreensoesGflu`, [{}]);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          &nbsp; Adicionar Apreensão
                        </Button>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
                <FormGroup className="text-right mt-4">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      this.context.setContent("RelatorioGfluList");
                      setValues({
                        ...values,
                        AtividadeGflus: [],
                        PrisoesGflu: [],
                        ApreensoesGflu: [],
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    &nbsp; Fechar
                  </Button>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default RelatorioGfluForm;
