import React from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  ListGroup,
  ListGroupItem,
  Nav,
  Row,
  Tab,
  TabContainer,
  TabContent,
  Tabs,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "../../context";
import {
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { Field, Form, Formik } from "formik";
import Request from "../../request";


class MyProfilePage extends React.Component {
  static contextType = AppContext;

  save(values, callback){
    const url = this.context.config.BACKEND_URL +'/usuario/edit/'+this.context.usuario.id;
    Request("PUT", url, this.context.token)
    .send(values)
    .then( (res) => {
        alert("Alterações realizadas com sucesso");
        this.context.setContent("DashboardPage");    
        window.location.reload();
                   
    })
    .catch( err => {
        this.context.addToast({titulo: "Erro", conteudo: "Houve uma falha na gravação do registro."});
        callback();
    });

}

  render() {
    {console.log(this.context.usuario)}
    const links = this.context.usuario.Modulos.map((modulo, key) => {
      return (
        <Nav.Item key={key}>
          <Nav.Link eventKey={modulo.nome}>{modulo.titulo}</Nav.Link>
        </Nav.Item>
      );
    });

    const panes = this.context.usuario.Modulos.map((modulo, key) => {
      return (
        <Tab.Pane key={key} eventKey={modulo.nome}>
          <FormGroup>
            <strong>Descrição do Módulo</strong>
            <FormControl readonly value={modulo.descricao} />
          </FormGroup>
          <FormGroup>
            <strong>Perfis Associados</strong>
            <FormControl
              readonly
              value={modulo.Perfis.map((p) => p.nome).join(", ")}
            />
          </FormGroup>
        </Tab.Pane>
      );
    });

    const permissoes = this.context.usuario.Permissoes.map((permissao, key) => (
      <ListGroupItem key={key}>
        <strong>{permissao.nome}</strong>
        <br />
        <em>{permissao.descricao}</em>
      </ListGroupItem>
    ));

    const risps = this.context.usuario.Risps.map((risp, key) => (
      <ListGroupItem key={key}>
        <strong>{risp.nome}</strong>
        <br />
      </ListGroupItem>
    ));

    return (
      <Formik

      initialValues={{
        login: this.context.usuario.login,
        nome: this.context.usuario.nome,
        email: this.context.usuario.email,
      }}

        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting,handleChange, values }) => {
          return (
            <Form>
              <Tabs defaultActiveKey="usuario">
                <Tab eventKey="usuario" title="Dados do Usuário">
                  <Row className="my-3">
                    <Col lg={{ span: 6, offset: 3 }}>
                      <FormGroup>
                        <FormLabel>
                          Login (CPF - somente numero) {"   "}
                        </FormLabel>
                        <Field name="login" className="form-control"  onChange={handleChange} value={values.login} />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>Nome</FormLabel>
                        <Field name="nome"  className="form-control"  onChange={handleChange}value={values.nome} />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <Field  name="email" className="form-control"   onChange={handleChange} value={values.email} />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>Órgão</FormLabel>
                        <FormControl
                          readonly
                          value={this.context.usuario.Orgao.nome}
                        />
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>Setor</FormLabel>
                        <FormControl
                          readonly
                          value={
                            this.context.usuario.Setor
                              ? this.context.usuario.Setor.nome
                              : "Sem setor definido"
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col lg={{ span: 6, offset: 3 }}>
                    <FormGroup className="text-right">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="primary"
                        className="ml-2"
                      >
                        <FontAwesomeIcon icon={faSave} />
                        &nbsp; Atualizar Dados
                      </Button>
                    </FormGroup>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="modulos" title="Módulos e Perfis">
                  <TabContainer>
                    <Row className="my-3">
                      <Col lg={3}>
                        <h4>Módulos</h4>
                        <Nav variant="pills" className="flex-column">
                          {links}
                        </Nav>
                      </Col>
                      <Col lg={9}>
                        <TabContent>{panes}</TabContent>
                      </Col>
                    </Row>
                  </TabContainer>
                </Tab>
                <Tab eventKey="permissoes" title="Permissões">
                  <Row className="my-3">
                    <Col lg={{ span: 6, offset: 3 }}>
                      <ListGroup>{permissoes}</ListGroup>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="risps" title="RISPs">
                  <Row className="my-3">
                    <Col lg={{ span: 6, offset: 3 }}>
                      <ListGroup>{risps}</ListGroup>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
export default MyProfilePage;
