import React from 'react';
import Request from '../../../request';
import { AppContext } from "../../../context";
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datatable from '../../Datatable';

class AvaliarDenunciaList extends React.Component {

    static contextType = AppContext;
    onAction(action, update) {
        switch (action.name) {
            case "class":
                Request('GET', this.context.config.BACKEND_URL + '/denuncia/validarApenso/' + action.id, this.context.token)
                    .then(res => {
                        this.context.setContent("ValidarApensoForm", { validar: res.body });
                    })
                    .catch(err => {
                        this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                    });
                break;
            default:
        }
    }
    render() {
        return (
            <>
                <h3><FontAwesomeIcon icon={faFolderOpen} /> Validar Apensos</h3>
                <hr />
                <Datatable
                    url={this.context.config.BACKEND_URL + '/denuncia/validarApenso'}
                    autoSeach={true}
                    onError={(err) => this.context.addToast({titulo: "Erro", conteudo: err.toString()})}
                    onAction={(action, update) => this.onAction(action, update)}
                />
            </>);
    }
}

export default AvaliarDenunciaList;