import React, { Component } from "react";
import Request from "../../../request";
import { AppContext } from "../../../context";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import {
  faCalendarAlt,
  faFlag,
  faSearch,
  faShieldAlt,
  faShip,
  faTable,
  faTrashAlt,
  faUsers,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

const formatter = new Intl.NumberFormat("pt-Br", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

class DashboardGflu extends Component {
  static contextType = AppContext;
  state = {
    quantidadePorTipoAtividade: [],
    quantidadeEntorpecentes: [],
    quantidadePrisoes: [],
    quantidadeApreensoes: [],
    quantidadePorOrgao: [],
    quantidadePorSetor: [],
    embarcacoes: [],
    embarcacaoSemRelatorio: [],
    embarcacoesInativas: [],
    apreensoesAgrupadas: [],
    entorpecentesAgrupados: [],
    filteredEmbarcacoes: [],
    filteredOrgaos: [],
    filteredSetores: [],
    setores: [],
    orgaos: [],
    dataInicial: "",
    dataFinal: "",
    horimetroNoPeriodo: null,
    embarcacaoId: null,
    orgaoId: null,
    setorId: null,
  };

  fetchData = () => {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    let { dataInicial, dataFinal, orgaoId, setorId, embarcacaoId } = this.state;

    if (dataInicial && dataFinal) {
      if (dataInicial > dataFinal) {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Data inicial não pode ser maior que a data final.",
        });
      }
    }

    let url = `${this.context.config.BACKEND_URL}/relatorio/dashboard`;

    if (dataInicial && dataFinal) {
      url += `/${dataInicial}/${dataFinal}`;
    } else if (dataInicial) {
      url += `/${dataInicial}/*`;
    } else if (dataFinal) {
      url += `/*/${dataFinal}`;
    } else {
      url += `/*/*`;
    }

    if (embarcacaoId) {
      url += `/${embarcacaoId}`;
    } else {
      url += `/*`;
    }

    if (orgaoId) {
      url += `/${orgaoId}`;
    } else {
      url += `/*`;
    }

    if (setorId) {
      url += `/${setorId}`;
    }

    Request("GET", url, this.context.token)
      .send()
      .then((response) => {
        this.setState({
          quantidadePorTipoAtividade: response.body.countPorTipoAtividade,
          quantidadeEntorpecentes: response.body.entorpecentesAgrupados,
          quantidadePrisoes: response.body.countPrisao,
          quantidadeApreensoes: response.body.countApreensao,
          filteredOrgaos: response.body.countOrgao,
          filteredSetores: response.body.countSetor,
          filteredEmbarcacoes: response.body.countEmbarcacao,
          horimetroNoPeriodo: response.body.horimetroNoPeriodo,
          embarcacaoSemRelatorio: response.body.embarcacoesSemRelatorio,
          embarcacoesInativas: response.body.embarcacoesInativas,
          apreensoesAgrupadas: response.body.apreensoesAgrupadas,
          entorpecentesAgrupados: response.body.ApreensaoGflu
        });
      })
      .catch(errorHandler);
  };

  handleFilterButtonClick = () => {
    const { dataInicial, dataFinal } = this.state;

    if (dataInicial && dataFinal) {
      this.setState(
        {
          dataInicial: this.formatarDataFinal(dataInicial),
          dataFinal: this.formatarDataFinal(dataFinal),
        },
        this.fetchData
      );
    } else if (dataFinal) {
      this.setState(
        {
          dataFinal: this.formatarDataFinal(dataFinal),
        },
        this.fetchData
      );
    } else if (dataInicial) {
      this.setState(
        {
          dataInicial: this.formatarDataFinal(dataInicial),
        },
        this.fetchData
      );
    } else {
      this.fetchData();
    }
  };

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/grupamentoFluvial/embarcacao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ embarcacoes: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);

    this.fetchData();
  }

  formatarDataFinal(data) {
    if (typeof data === "string") {
      data = new Date(data);
    }

    if (data instanceof Date && !isNaN(data)) {
      const ano = data.getFullYear();
      const mes = String(data.getMonth() + 1).padStart(2, "0");
      const dia = String(data.getDate()).padStart(2, "0");
      const dataFormatada = `${ano}-${mes}-${dia}`;

      return dataFormatada;
    } else {
      console.error("Erro: data não é uma data válida.");
      return "";
    }
  }

  handleResetFilters = () => {
    this.setState(
      {
        dataInicial: "",
        dataFinal: "",
        embarcacaoId: null,
        orgaoId: null,
        setorId: null,
      },
      () => {
        this.fetchData();
      }
    );
  };

  render() {
    const {
      quantidadePorTipoAtividade,
      quantidadeEntorpecentes,
      quantidadePrisoes,
      quantidadeApreensoes,
      filteredEmbarcacoes,
      filteredOrgaos,
      filteredSetores,
      horimetroNoPeriodo,
      embarcacaoSemRelatorio,
      embarcacoesInativas,
      entorpecentesAgrupados,
      apreensoesAgrupadas
    } = this.state;
    const variants = ["primary", "warning", "danger", "success", "info"];

    const randomNumberInRange = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    return (
      <>
        <h3>
          <FontAwesomeIcon icon={faFlag} /> Dashboard
        </h3>
        <hr />
        <div style={{ margin: "0 auto", width: "90%" }}>
          <Row>
            <Col md={4} className="mt-2">
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
              <span style={{ whiteSpace: "nowrap" }}>Data Inicial</span>
              <input
                type="date"
                name="dataInicial"
                className="form-control ml-2 mr-2"
                value={this.state.dataInicial}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={4} className="mt-2">
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
              <span style={{ whiteSpace: "nowrap" }}>Data Final</span>
              <input
                type="date"
                name="dataFinal"
                className="form-control ml-2 mr-2"
                value={this.state.dataFinal}
                onChange={handleInputChange}
              />
            </Col>

            <Col md={4} className="mt-2">
              <FontAwesomeIcon icon={faShip} />
              <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
                Embarcação
              </span>
              <Select
                className="ml-1"
                name="embarcacaoId"
                style={{ width: "160px" }}
                noOptionsMessage={(text) =>
                  `${text.inputValue} não encontrado!`
                }
                menuPortalTarget={document.body}
                placeholder="Selecione uma embarcação..."
                options={this.state.embarcacoes.map((embarcacao) => ({
                  label: embarcacao.nome,
                  value: embarcacao.id,
                }))}
                onChange={(selectedOption) => {
                  const selectedEmbarcacaoId = selectedOption
                    ? selectedOption.value
                    : null;
                  this.setState({ embarcacaoId: selectedEmbarcacaoId });
                }}
              />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={4} className="mt-2">
              <FontAwesomeIcon icon={faShieldAlt} />
              <span className="ml-2" style={{ whiteSpace: "nowrap" }}>
                Orgão
              </span>
              <Select
                className="ml-1"
                name="orgaoId"
                style={{ width: "160px" }}
                noOptionsMessage={(text) =>
                  `${text.inputValue} não encontrado!`
                }
                menuPortalTarget={document.body}
                placeholder="Selecione um orgão..."
                options={this.state.orgaos}
                onChange={(selectedOption) => {
                  const selectedOrgaoId = selectedOption
                    ? selectedOption.value
                    : null;
                  this.setState({ orgaoId: selectedOrgaoId });
                }}
              />
            </Col>
            <Col md={4} className="mt-2">
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              <span style={{ whiteSpace: "nowrap" }}>Setor </span>
              <Select
                className="ml-1"
                name="setorId"
                style={{ width: "160px" }}
                noOptionsMessage={(text) =>
                  `${text.inputValue} não encontrado!`
                }
                menuPortalTarget={document.body}
                placeholder="Selecione um setor..."
                options={this.state.setores.filter(
                  (setor) => setor.OrgaoId === this.state.orgaoId
                )}
                onChange={(selectedOption) => {
                  const selectedSetorId = selectedOption
                    ? selectedOption.value
                    : null;
                  this.setState({ setorId: selectedSetorId });
                }}
              />
            </Col>
            <Col md={4} className="mt-4">
              <Button
                className="mt-2"
                variant="warning"
                onClick={this.handleResetFilters}
                style={{ width: "50%" }}
              >
                <FontAwesomeIcon icon={faTrashAlt} /> Resetar filtros{" "}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mt-2">
              <Button
                className="mt-2"
                variant="success"
                onClick={this.handleFilterButtonClick}
                style={{ width: "50%" }}
              >
                <FontAwesomeIcon icon={faSearch} /> Filtrar{" "}
              </Button>
            </Col>
          </Row>
        </div>
        <hr />
        <Row>
          <Col>
            <Card >
              <Card.Header className="bg-danger text-white">
                <FontAwesomeIcon icon={faExclamationTriangle} /> Embarcação SEM ENVIO de
                Relatório
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {embarcacaoSemRelatorio.map((embarcacao, index) => {
                    return (
                      <Col
                        md={
                          embarcacao.count <
                          embarcacaoSemRelatorio.length -
                            (embarcacaoSemRelatorio.length % 4)
                            ? 3
                            : 12 / (embarcacaoSemRelatorio.length % 4)
                        }
                        key={index}
                      >
                        <Col md={12}>
                          <Card
                            bg={variants[randomNumberInRange(0, 4)]}
                            className="text-white mb-3"
                            style={{ height: "50px" }}
                          >
                            <Card.Header>{embarcacao.nome}</Card.Header>
                          </Card>
                        </Col>
                      </Col>
                    );
                  })}
                </Row>
              </Card.Body>
            </Card>

            <br />

            <Card >
              <Card.Header className="bg-warning text-white">
                <FontAwesomeIcon icon={faExclamationTriangle} /> Embarcações INATIVAS
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {embarcacoesInativas.map((embarcacao, index) => {
                    return (
                      <Col
                        md={
                          embarcacao.count <
                          embarcacoesInativas.length -
                            (embarcacoesInativas.length % 4)
                            ? 3
                            : 12 / (embarcacoesInativas.length % 4)
                        }
                        key={index}
                      >
                        <Col md={12}>
                          <Card
                            bg={variants[randomNumberInRange(0, 4)]}
                            className="text-white mb-3"
                            style={{ height: "50px" }}
                          >
                            <Card.Header>{embarcacao.nome}</Card.Header>
                          </Card>
                        </Col>
                      </Col>
                    );
                  })}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Relatórios por Embarcação
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {filteredEmbarcacoes.map((embarcacao, index) => {
                    const horimetro =
                      horimetroNoPeriodo.find(
                        (h) => h.embarcacao_id === embarcacao.id
                      )?.horimetroNoPeriodo || 0;

                    return (
                      <Col
                        md={
                          embarcacao.count <
                          filteredEmbarcacoes.length -
                            (filteredEmbarcacoes.length % 4)
                            ? 3
                            : 12 / (filteredEmbarcacoes.length % 4)
                        }
                        key={index}
                      >
                        <Card
                          bg={variants[randomNumberInRange(0, 4)]}
                          className="text-white mb-3"
                          style={{ height: "150px" }}
                        >
                          <Card.Header>{embarcacao.nome}</Card.Header>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <Row className="mt-1">
                              <Col md={12}>
                                <p>
                                  <span>Horímetro no período: </span>
                                  <span>
                                    <h3 className="d-inline">
                                      {formatter.format(horimetro)}
                                    </h3>
                                  </span>
                                </p>
                              </Col>
                              <Col md={12}>
                                <p>
                                  <span>Quantidade de relatórios: </span>
                                  <span>
                                    <h3 className="d-inline">
                                      {formatter.format(embarcacao.count)}
                                    </h3>
                                  </span>
                                </p>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Relatórios por Órgão
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {filteredOrgaos.map((orgao, index) => (
                    <Col
                      md={
                        orgao.count <
                        filteredOrgaos.length - (filteredOrgaos.length % 4)
                          ? 3
                          : 12 / (filteredOrgaos.length % 4)
                      }
                    >
                      <Card
                        key={index}
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{orgao.nome}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          {formatter.format(filteredOrgaos).length < 10 ? (
                            <h1>{formatter.format(orgao.count)}</h1>
                          ) : (
                            <h3>{formatter.format(orgao.count)}</h3>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Relatórios por Setor
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {filteredSetores.map((setor, index) => (
                    <Col
                      key={index}
                      md={
                        setor.count <
                        filteredSetores.length - (filteredSetores.length % 4)
                          ? 3
                          : 12 / (filteredSetores.length % 4)
                      }
                    >
                      <Card
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{setor.nome}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          {formatter.format(filteredSetores).length < 10 ? (
                            <h1>{formatter.format(setor.count)}</h1>
                          ) : (
                            <h3>{formatter.format(setor.count)}</h3>
                          )}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Atividades por Tipo
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  {quantidadePorTipoAtividade.map((atividade, index) => (
                    <Col md={4} key={index}>
                      <Card
                        bg={variants[randomNumberInRange(0, 4)]}
                        className="text-white mb-3"
                        style={{ height: "150px" }}
                      >
                        <Card.Header>{atividade.tipo}</Card.Header>
                        <Card.Body className="d-flex align-items-end justify-content-end">
                          <h1>{atividade.totalQuantidade}</h1>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Quantidade de Entorpecentes
                por Tipo e Unidade
              </Card.Header>
              <Card.Body>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Quantidade</th>
                      <th>Unidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quantidadeEntorpecentes &&
                    quantidadeEntorpecentes.length > 0 ? (
                      quantidadeEntorpecentes.map((atividade, index) => (
                        <tr key={index}>
                          <td>{atividade.tipo}</td>
                          <td>{atividade.totalQuantidade}</td>
                          <td>{atividade.unidade}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          Nenhum dado encontrado.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Total de Prisões
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  <Col md={4}>
                    <Card
                      bg={variants[randomNumberInRange(0, 4)]}
                      className="text-white mb-3"
                      style={{ height: "150px" }}
                    >
                      <Card.Header>Prisões</Card.Header>
                      <Card.Body className="d-flex align-items-end justify-content-end">
                        <h1>{quantidadePrisoes}</h1>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Total de Apreensões
              </Card.Header>
              <Card.Body>
                <Row className="d-flex">
                  <Col md={4}>
                    <Card
                      bg={variants[randomNumberInRange(0, 4)]}
                      className="text-white mb-3"
                      style={{ height: "150px" }}
                    >
                      <Card.Header>Apreensões</Card.Header>
                      <Card.Body className="d-flex align-items-end justify-content-end">
                        <h1>{quantidadeApreensoes}</h1>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <br />

        <Card>
              <Card.Header>
                <FontAwesomeIcon icon={faTable} /> Itens Apreendidos
              </Card.Header>
              <Card.Body>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Coisa</th>
                      <th>Tipo</th>
                      <th>Quantidade</th>
                      <th>descrição</th>
                      <th>Numero_boletim</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apreensoesAgrupadas &&
                    apreensoesAgrupadas.length > 0 ? (
                      apreensoesAgrupadas.map((atividade, index) => (
                        <tr key={index}>
                          <td>{atividade.coisa}</td>
                          <td>{atividade.tipo}</td>
                          <td>{atividade.quantidade}</td>
                          <td>{atividade.descricao}</td>
                          <td>{atividade.numero_boletim}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          Nenhum dado encontrado.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <br />
      </>
    );
  }
}

export default DashboardGflu;
