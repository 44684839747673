import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faCubes, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { AppContext } from '../../context';
import Request from '../../request';

class DashboardPage extends React.Component {

    static contextType = AppContext;

    state = {
        avisos: [],
        modal : false
    }

    componentDidMount() {
        this.context.setTitle('Painel de Módulos');

        if (this.context.usuario.login.length > 11) {
            this.setState({ modal: true });
          }

        Request('GET', this.context.config.BACKEND_URL + '/aviso/geral', this.context.token)
            .then(res => {
                this.setState({ avisos: res.body });
            })
            .catch(err => {
                this.context.addToast({ title: 'Erro', text: 'Falha na recuperação da lista de módulos: ' + err.toString() });
            });
    }
    handleClose = () => {
        this.setState({ modal: false });
    };

    render() {

        const modulos = this.context.usuario?.Modulos.map((modulo, key) => {

            const imagem = modulo.imagem ?
                <Card.Img variant="top" src={modulo.imagem} className="p-2" />
                : null;

            return (

                <Col lg={6} key={key} className="d-flex mb-3">
                    <Card role="button" className="align-self-stretch w-100" onClick={() => this.context.setModule(modulo.nome)}>
                        <Card.Header>
                            <Card.Title>{modulo.titulo}</Card.Title>
                        </Card.Header>
                        {imagem}
                        <Card.Body className='text-justify'>
                            {modulo.descricao}
                        </Card.Body>
                    </Card>
                </Col>

            );
        });
        return (
            <>
             <Modal show={this.state.modal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Aviso</Modal.Title>
          </Modal.Header>
          <Modal.Body>O seu login não está padronizado com as regras do sistema.
            Clique no seu nome no canto superior direito, depois vá em meu dados e altere seu login para o seu CPF

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
             <Row className='h-100'>
            <Col lg="8" className='h-100 overflow-auto'>
                <h3><FontAwesomeIcon icon={faBullhorn} /> Seja bem-vindo ao PISP</h3>
                <hr />
                <Card className='p-3 bg-light text-dark mb-5 text-justify'>
                    <p>O gerenciamento de informações é uma ferramenta eficiente para o melhoramento
                    das ações integradas do Sistema Estadual de Segurança Pública e Defesa Social
                    (SIEDS) no estado do Pará. Neste raciocínio, no ano de 2022, iniciou-se o projeto de
                    elaboração da Plataforma Integrada de Segurança Pública (PISP), com o intuito de
                    gerar informação a partir dos dados sistematizados e dos relatórios das ações e
                    operações integradas dos órgãos de segurança pública (OSP).</p>
                    <p>A aplicação da Gestão do informação (GI) nesse contexto se justifica, uma vez que o
                    desenvolvimento de software consiste em uma atividade de mudanças rápidas,
                    capazes de proporcionar conhecimento intensivo, no qual se dá entre diversos
                    indivíduos de cada instituição, atuando simultaneamente, em diversas fases e
                    atividades (NONAKA;TAKEUCHI, 1997; TENÓRIO, 2023).</p>
                    <p>Nesta racionalidade, optou-se pelo estabelecimento de três pilares fundamentais
                    para a implementação desta ferramenta gerencial, a saber: o planejamento, a
                    integração e a organização. O planejamento visa estabelecer os critérios necessários
                    para a elaboração de operações integradas e ações rotineiras que ocorrem em cada
                    região integrada de segurança pública (RISP). Nesta fase, são explicitados os
                    prazos, o local, os indicadores específicos, os órgãos envolvidos e os recursos
                    necessários para a materialização das ações.</p>
                    <p>De forma adicional, a integração objetiva fortalecer os laços institucionais,
                    proporcionando oportunidades e robustez na correta implementação dos recursos
                    humanos, orçamentários e logísticos necessários para o alcance dos objetivos. Esta
                    perspectiva será realizada por intermédio da articulação, do envolvimento dos
                    gestores no processo e com a participação dos usuários das instituições em cada
                    operação. Destarte, se faz importante ressaltar que, cada ação deverá ser ser
                    liderada por um único órgão específico, o qual será denominado “órgão líder”. Este
                    responsável ficará incumbido de alimentar as informações das outras instituições nos
                    dados gerais das operações realizadas. Desta forma, se evitará a alimentação de
                    uma mesma ação por diversos OSP, evitando-se informações duplicadas,
                    proporcionando maior coordenação e controle pelos técnicos responsáveis.</p>
                    <p className='mt-3 text-right'>
                        Fonte: Manual do Usuário do PISP
                    </p>

                </Card>

                <h3><FontAwesomeIcon icon={faNewspaper} /> Quadro de Avisos</h3>
                <hr />
                {this.state.avisos.map(aviso =>
                    <Card>
                        <Card.Header>{aviso.titulo}</Card.Header>
                        <Card.Body>
                            <div dangerouslySetInnerHTML={{ __html: aviso.conteudo }} />
                        </Card.Body>
                        <Card.Footer>
                            <div className='text-right'>
                                <i><small><b>Atualizado em: </b>{new Date(aviso.updatedAt).toLocaleString()}</small></i>
                            </div>
                        </Card.Footer>
                    </Card>)}
            </Col>
            <Col className='h-100  overflow-auto'>
                <h3><FontAwesomeIcon icon={faCubes} /> Módulos</h3>
                <hr />
                <Row>{modulos}</Row>
            </Col>
        </Row>;
            </>
        )
        
       
    }
}

export default DashboardPage;