import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { AppContext } from "../context";

class AddAnexos extends Component {
  static contextType = AppContext;

  uploadRef = React.createRef();

  state = {
    loading: false,
    progress: 0,
  };

  loadFiles = (files) => {
    if (!files || files.length === 0) return;

    const { extraInfo, onLoad } = this.props;
    const total = Array.from(files).reduce((sum, file) => sum + file.size, 0);
    let loaded = 0;

    this.setState({ loading: true, progress: 0 });

    const anexos = Array.from(files).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          loaded += file.size;
          this.setState({ progress: (loaded / total) * 100 });

          resolve({
            id: null,
            nome: `${extraInfo || "Anexo"}_${file.name}`, // Adicionando extraInfo ao nome
            tipo: file.type,
            tamanho: file.size,
            conteudo: e.target.result.split(",")[1],
          });
        };

        reader.onerror = (error) => reject(error);
      });
    });

    Promise.all(anexos)
      .then((result) => {
        this.setState({ loading: false, progress: 100 });
        if (onLoad) onLoad(result);
      })
      .catch(() => {
        this.setState({ loading: false });
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Falha ao carregar os arquivos.",
        });
      });
  };

  handleUpload = (event) => {
    this.loadFiles(event.target.files);
  };

  render() {
    const { readOnly, multiple } = this.props;
    const { loading, progress } = this.state;

    return (
      <div>
        <input
          type="file"
          ref={this.uploadRef}
          style={{ display: "none" }}
          multiple={multiple}
          disabled={readOnly}
          onChange={this.handleUpload}
        />

        <Button
          className="form-control" 
          variant="success"
          onClick={() => this.uploadRef.current?.click()}
          disabled={readOnly}
        >
          <FontAwesomeIcon icon={faPlus} /> Adicionar Anexo
        </Button>

        {loading && <ProgressBar now={progress} label={`${Math.round(progress)}%`} />}
      </div>
    );
  }
}

export default AddAnexos;
