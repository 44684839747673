import React from "react";
import { AppContext } from "../../../../context";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faSearch,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Request from "../../../../request";
import { Bar, PolarArea, Pie } from "react-chartjs-2";
import Select from "react-select";
import "./index.css";

class ScoreCard extends React.Component {
  static contextType = AppContext;

  state = {
    emDiligencia: 0,
    finalizada: 0,
    encaminhada: 0,
    recebida: 0,
    denuncias: [],
    historicos: [],
    naturezas: [],
    canais: [],
    setores: [],
    dataInicial: null,
    dataFinal: null,
    alertas: [],
    showAlerta: true,
    showModal: false,
  };

  componentDidMount() {
    this.fetchData();

    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa: " + err.toString(),
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/denuncia/alertaApenso",
      this.context.token
    )
      .send()
      .then((req) => {
        this.setState(() => ({ alertas: req.body }));
        const mensagem = `A denúncia com ID ${req.body[0].id} tem um novo apenso`;
        this.handleShowModal(mensagem);
      })
      .catch((error) => {
        console.error("Erro ao buscar alertas:", error);
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options/disquedenuncia",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);
  }

  handleShowModal = (mensagem) => {
    this.setState({ showModal: true, alertaAtual: mensagem });
  };

  handleCloseModal = async () => {
    this.setState({ showModal: false, alertaAtual: "" });
    const { alertas } = this.state;

    try {
      const requests = alertas.map((alerta) => {
        const url = `${this.context.config.BACKEND_URL}/denuncia/apensoLido/${alerta.id}`;
        return Request("PUT", url, this.context.token).send();
      });
      await Promise.all(requests);
      this.context.addToast({
        titulo: "Sucesso",
        conteudo: "Todos os apensos foram atualizados com sucesso.",
      });
    } catch (err) {
      console.error("Erro ao atualizar os apensos:", err);
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na gravação dos registros.",
      });
    }
  };

  fetchData = async () => {
    let { dataInicial, dataFinal,selectedOrgao, selectedSetor } = this.state;
    let url1 = `${this.context.config.BACKEND_URL}/denuncia/scorecardDenuncia`;
    let url2 = `${this.context.config.BACKEND_URL}/denuncia/scorecardHistorico`;
    // Validação da Data
    if (dataInicial && dataFinal && dataInicial > dataFinal) {
      return this.context.addToast({
        titulo: "Erro",
        conteudo: "Data inicial não pode ser maior que a data final.",
      });
    }

    // Construção da URL apenas se houver parâmetros
    let params = [
      dataInicial ? dataInicial : "*",
      dataFinal ? dataFinal : "*",
      selectedOrgao ? selectedOrgao : "*",
      selectedSetor ? selectedSetor : "*",
    ];

    url1 += `/${params.join("/")}`;
    url2 += `/${params.join("/")}`;

    try {
      let res1 = await Request("GET", url1, this.context.token);
      let res2 = await Request("GET", url2, this.context.token);

      this.setState({
        denuncias: res1.body.denunciaPrazos,
        historicos: res2.body.denunciaPrazos,
        emDiligencia: res1.body.countEmDiligencia,
        recebida: res1.body.countRecebida,
        encaminhada: res1.body.countEncaminhada,
        finalizada: res1.body.countFinalizada,
        naturezas: res1.body.naturezas,
        canais: res1.body.canal,
      });
    } catch (err) {
      this.context.addToast({
        titulo: "Erro",
        conteudo:
          "Falha na recuperação da lista de permissões: " + err.toString(),
      });
    }
  };

  handleFilterButtonClick = () => {
    this.fetchData();
  };

  handleResetFilters = () => {
    this.setState(
      {
        dataSelecionada: null,
        selectedSetor: null,
        selectedOrgao:null
      },
      () => {
        this.fetchData();
      }
    );
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSetorChange = (selectedOption) => {
    this.setState({ selectedSetor: selectedOption.value });
  };

  getCanaisDataForChart = () => {
    const { canais } = this.state;

    // Converte a estrutura de dados
    const canalCounts = canais.reduce((acc, denuncia) => {
      const canalNome = denuncia.Canal?.nome || "Desconhecido";
      acc[canalNome] = (acc[canalNome] || 0) + denuncia.count;
      return acc;
    }, {});

    return {
      labels: Object.keys(canalCounts), // Nomes dos canais
      datasets: [
        {
          data: Object.values(canalCounts), // Quantidade de denúncias por canal
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
        },
      ],
    };
  };

  render() {
    const { setores } = this.state;

    const backgroundColors = [
      "#FF6384", // Cor para a primeira natureza
      "#36A2EB", // Cor para a segunda natureza
      "#FFCE56", // Cor para a terceira natureza
      "#4BC0C0", // Cor para a quarta natureza
      "#9966FF", // Cor para a quinta natureza
      "#FF9F40", // Cor para a sexta natureza
      "#C9CBCF", // Cor para a sétima natureza
      "#8D6E63", // Cor para a oitava natureza
      "#D4E157", // Cor para a nona natureza
      "#AB47BC", // Cor para a décima natureza
    ];
    const dataStatus = {
      labels: ["Encaminhadas", "Recebidas", "Em Diligência", "Finalizadas"],
      datasets: [
        {
          data: [
            this.state.encaminhada,
            this.state.recebida,
            this.state.emDiligencia,
            this.state.finalizada,
          ],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
        },
      ],
    };
    const naturezaCounts = this.state.naturezas.reduce((acc, denuncia) => {
      const naturezaNome = denuncia.Natureza
        ? denuncia.Natureza.nome
        : "Desconhecido";
      acc[naturezaNome] = (acc[naturezaNome] || 0) + denuncia.count;
      return acc;
    }, {});

    // Prepara os dados para o gráfico de barras
    const dataNaturezas = {
      labels: Object.keys(naturezaCounts), // Nomes das naturezas
      datasets: [
        {
          label: "Total de Denúncias",
          data: Object.values(naturezaCounts), // Contagens das naturezas
          backgroundColor: backgroundColors.slice(
            0,
            Object.keys(naturezaCounts).length
          ), // Usa apenas as cores necessárias
          hoverBackgroundColor: backgroundColors.slice(
            0,
            Object.keys(naturezaCounts).length
          ),
        },
      ],
    };

    const dataCanal = this.getCanaisDataForChart();
    return (
      <>
        <Row className="d-flex align-items-center mt-4">
          <Modal
            show={this.state.showModal}
            onHide={this.handleCloseModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Alerta</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.alertaAtual}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
          <Col md={4}>
            <Form.Label>Setor</Form.Label>
            <Select
              name="SetorId"
              noOptionsMessage={() => "Nada encontrado."}
              placeholder="Pesquisar..."
              menuPortalTarget={document.body}
              menuShouldBlockScroll={true}
              options={setores}
              value={
                setores.find(
                  (option) => option.value === this.state.selectedSetor
                ) || null
              } // Define o valor para null quando resetado
              onChange={this.handleSetorChange}
            />
          </Col>
          <Col className="d-flex align-items-center mt-4" md={4}>
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            <span style={{ whiteSpace: "nowrap" }}>Data Inicial</span>
            <input
              type="datetime-local"
              name="dataInicial"
              className="form-control ml-2"
              value={this.state.dataInicial || ""} // Define como string vazia quando resetado
              onChange={this.handleInputChange}
            />
          </Col>
          <Col className="d-flex align-items-center mt-4" md={4}>
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            <span style={{ whiteSpace: "nowrap" }}>Data Final</span>
            <input
              type="datetime-local"
              name="dataFinal"
              className="form-control ml-2"
              value={this.state.dataFinal || ""} // Define como string vazia quando resetado
              onChange={this.handleInputChange}
            />
          </Col>
        </Row>

        <Row className="my-3">
          <Col className="d-flex justify-content-end">
            <Button
              className="mx-2"
              variant="primary"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleFilterButtonClick}
            >
              <FontAwesomeIcon icon={faSearch} className="mr-2" />
              Filtrar
            </Button>
            <Button
              className="mx-2"
              variant="danger"
              style={{ whiteSpace: "nowrap" }}
              onClick={this.handleResetFilters}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
              Limpar
            </Button>
          </Col>
        </Row>

        <Row className="linha_cards justify-content-md-center mt-2">
          <Col md={4}>
            <Card className="text-white" style={{ backgroundColor: "#4B0082" }}>
              <Card.Body>
                <Card.Title>Resolutividade</Card.Title>
                <Card.Text>
                  {this.state.historicos.finalizadasNoPrazo > 0
                    ? `${(
                        (this.state.historicos.finalizadasNoPrazo /
                          this.state.historicos.enviadas) *
                        100
                      ).toFixed(2)}%`
                    : "0%"}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4  linha_cards">
          <Col md={4}>
            <Card className="card-prazo no-prazo">
              <Card.Body>
                <Card.Title>No Prazo</Card.Title>
                <Card.Text>{this.state.denuncias.noPrazo}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-prazo prorrogada">
              <Card.Body>
                <Card.Title>Prorrogada</Card.Title>
                <Card.Text>{this.state.denuncias.prorrogada}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="card-prazo atrasada">
              <Card.Body>
                <Card.Title>Atrasada</Card.Title>
                <Card.Text>{this.state.denuncias.atrasada}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4  linha_cards">
          <Col md={4}>
            <Card className="bg-primary text-white">
              <Card.Body>
                <Card.Title>Recebidas</Card.Title>
                <Card.Text>{this.state.historicos.enviadas}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="text-white" style={{ backgroundColor: "#228B22" }}>
              <Card.Body>
                <Card.Title>Finalizadas ou no Prazo</Card.Title>
                <Card.Text>
                  {this.state.historicos.finalizadasNoPrazo}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="text-white" style={{ backgroundColor: "#FF0000" }}>
              <Card.Body>
                <Card.Title>Não Finalizadas </Card.Title>
                <Card.Text>
                  {this.state.historicos.naoFinalizadasForaDoPraso}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="d-flex justify-content-between linha_graficos">
          <Col md={5} className="grafico_sombra my-3">
            <div className="text-letf">
              <h3 className="titulo-chart-disquedenuncia">
                Denúncias por Status
              </h3>
            </div>
            <div className="chart-container-disquedenuncia">
              <PolarArea data={dataStatus} />
            </div>
          </Col>

          <Col md={5} className="grafico_sombra my-3">
            <div className="text-left">
              <h3 className="titulo-chart-disquedenuncia">
                Canais das Denúncias
              </h3>
            </div>
            <div className="chart-container-disquedenuncia">
              <Pie data={dataCanal} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="grafico_sombra my-3">
            <div className="text-left">
              <h3 className="titulo-chart-disquedenuncia">
                Natureza das Denúncias
              </h3>
            </div>
            <div className="chart-container-disquedenuncia-bar">
              <Bar data={dataNaturezas} />
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ScoreCard;
