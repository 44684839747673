import {
  faDownload,
  faExclamationCircle,
  faSave,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Col,
  Form as BSForm,
  FormGroup,
  Row,
  Tab,
  Table,
  Tabs,
  Card,
  FormControl,
} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AddAnexos from "../../AddAnexos";
import { AppContext } from "../../../context";
import policeUnitsGeoJSON from "./pontos/unidadesPoliciais.json";
import Map from "./Map";
import moment from "moment";
import CustomEditor from "../../Editor";

class EncaminharDenunciaForm extends React.Component {
  static contextType = AppContext;

  state = {
    cidades: [],
    bairros: [],
    canais: [],
    caracteristicas: [],
    denuncias: [],
    atendentes: [],
    relacionamentos: [],
    orgaos: [],
    setores: [],
    selectedCopias: [],
    idDoOrgao: 0,
    filteredPoliceUnits: [], // Lista de unidades filtradas
    activeKey: "dados-basicos",
   
  };

  anexoRef = React.createRef();
  timeout = null;

  haversineDistance(coords1, coords2) {
    const toRad = (angle) => (Math.PI / 180) * angle;
    const R = 6371; // Raio da Terra em quilômetros

    const dLat = toRad(coords2.latitude - coords1.latitude);
    const dLng = toRad(coords2.longitude - coords1.longitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(coords1.latitude)) *
        Math.cos(toRad(coords2.latitude)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Retorna a distância em quilômetros
  }

  getNearestPoliceUnits(denunciaCoords) {
    if (!denunciaCoords.latitude || !denunciaCoords.longitude) {
      return [];
    }

    const units = policeUnitsGeoJSON.features.map((feature) => {
      const { latitude, longitude, Name } = feature.properties;

      const correctedCoords = { latitude, longitude };

      const distance = this.haversineDistance(denunciaCoords, correctedCoords);

      return { name: Name, ...correctedCoords, distance };
    });

    // Pega os 5 pontos mais próximos
    const nearestUnits = units
      .filter((unit) => !isNaN(unit.distance)) // Filtra unidades com distância válida
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 5);

    return nearestUnits;
  }

  correctCoordinates(coordinates) {
    if (!coordinates || coordinates.length !== 2) {
      return { latitude: NaN, longitude: NaN };
    }

    // Verifique a ordem e garanta que latitude vem primeiro
    const [longitude, latitude] = coordinates.map((coord) => parseFloat(coord));

    // Verifique se a conversão para número foi bem-sucedida
    if (isNaN(latitude) || isNaN(longitude)) {
      return { latitude: NaN, longitude: NaN };
    }

    // Retorne as coordenadas no formato correto (latitude primeiro)
    return { latitude, longitude };
  }

  save(values, update) {
    const url =
      this.context.config.BACKEND_URL +
      "/denuncia/devolvidas/" +
      this.props.encaminhar.id;
    Request("PUT", url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        this.context.setContent("DenunciasDevolvidasList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
          err,
        });
      });
  }

  handleTabSelect = () => {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa: " + err.toString(),
      });
    Request(
      "GET",
      this.context.config.BACKEND_URL +
        "/denuncia/relacionamentos/" +
        this.props.encaminhar.id,
      this.context.token
    )
      .send()
      .then((response) => {
        this.setState(() => ({ relacionamentos: response.body }));
      })
      .catch(errorHandler);
  };

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa: " + err.toString(),
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/usuario/disquedenuncia/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ atendentes: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/canal/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ canais: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/natureza/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ naturezas: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/orgao/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ orgaos: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/setor/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ setores: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/bairro/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ bairros: options.body }));
      })
      .catch(errorHandler);

    const denunciaCoords = {
      latitude: this.props.encaminhar.latitude,
      longitude: this.props.encaminhar.longitude,
    };

    // Atualiza as unidades mais próximas no estado
    const nearestUnits = this.getNearestPoliceUnits(denunciaCoords);
    this.setState({ filteredPoliceUnits: nearestUnits });
  }

  render() {
    const toggleRow = (key) => {
      this.setState((prevState) => ({
        expandedRow: prevState.expandedRow === key ? null : key,
      }));
    };

    const options = [
      { value: "Devolvida ao Call Center", label: "Devolvida ao Call Center" },
      { value: "Encaminhada", label: "Encaminhada" },
      { value: "Finalizada", label: "Finalizada" },
      { value: "Arquivada", label: "Arquivada" },
      { value: "Avaliada", label: "Avaliada" },
      { value: "Recebida", label: "Recebida" },
      { value: "Em Diligencia", label: "Em Diligencia" },
    ];

    const motivo_arquivamento = [
      {
        value: "Denuncia com dados incompletos (Denunciado/Endereço/Relato",
        label: "Denuncia com dados incompletos (Denunciado/Endereço/Relato",
      },
      {
        value: "Denuncia direcionada a outro orgao",
        label: "Denuncia direcionada a outro orgao",
      },
      { value: "Denuncia duplicada", label: "Denuncia duplicada" },
      {
        value: "Denuncia com dados incompreensiveis/confusos",
        label: "Denuncia com dados incompreensiveis/confusos",
      },
      {
        value: "Outros",
        label: "Outros",
      },
    ];

    const optionsProcedente = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];

    const optionsAveriguada = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];

    const optionsProcedimento = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];
    const optionsBoletimOcorrencia = [
      { value: "Sim", label: "Sim" },
      { value: "Não", label: "Não" },
    ];
    const optionsNaoAveriguada = [
      { value: "ENDEREÇO NÃO LOCALIZADO", label: "ENDEREÇO NÃO LOCALIZADO" },
      {
        value: "DENUNCIADO(S) NÃO LOCALIZADO(S)",
        label: "DENUNCIADO(S) NÃO LOCALIZADO(S)",
      },
      {
        value: "VÍTIMA(S) NÃO LOCALIZADA(S)",
        label: "VÍTIMA(S) NÃO LOCALIZADA(S)",
      },
      { value: "INFORMAÇÕES INSUFICIENTE", label: "INFORMAÇÕES INSUFICIENTE" },
      {
        value: "REDIRECIONAMENTO DE DENÚNCIA",
        label: "REDIRECIONAMENTO DE DENÚNCIA",
      },
      {
        value: "DENÚNCIA PERTENCE A OUTRA CIRCUNSCRIÇÃO POLICIAL",
        label: "DENÚNCIA PERTENCE A OUTRA CIRCUNSCRIÇÃO POLICIAL",
      },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsTipoProcedimento = [
      { value: "PROCEDIMENTOS POLICIAIS", label: "PROCEDIMENTOS POLICIAIS" },
      {
        value: "PROCEDIMENTOS CORRECIONAIS",
        label: "PROCEDIMENTOS CORRECIONAIS",
      },
      {
        value: "PROCEDIMENTOS DE UNIDADES POLICIAIS ESPECIALIZADAS",
        label: "PROCEDIMENTOS DE UNIDADES POLICIAIS ESPECIALIZADAS",
      },
      {
        value: "PROCEDIMENTOS POLICIAIS MILITARES",
        label: "PROCEDIMENTOS POLICIAIS MILITARES",
      },
      {
        value: "PROCEDIMENTOS ADMINISTRATIVOS",
        label: "PROCEDIMENTOS ADMINISTRATIVOS",
      },
    ];

    const optionsDescProdecimentoPoliciais = [
      { value: "IPL/FLAGRANTE", label: "IPL/FLAGRANTE" },
      { value: "IPL/PORTARIA", label: "IPL/PORTARIA" },
      {
        value: "TCO (Termo Circunstanciado de Ocorrências)",
        label: "TCO (Termo Circunstanciado de Ocorrências)",
      },
    ];
    const optionsDescProdecimentoCorrecionais = [
      { value: "IPL (Inquérito Policial)", label: "IPL (Inquérito Policial)" },
      {
        value: "PAD (Processo Administrativo Disciplinar)",
        label: "PAD (Processo Administrativo Disciplinar)",
      },
      {
        value: "AAI (Apuração Adminitrativa Interna)",
        label: "AAI (Apuração Adminitrativa Interna)",
      },
      {
        value: "IPM (Inquérito Policial Militar)",
        label: "IPM (Inquérito Policial Militar)",
      },
      {
        value: "PADS (Processo Adminstrativo Disciplinar Simplificado)",
        label: "PADS (Processo Adminstrativo Disciplinar Simplificado)",
      },
      { value: "SINDICÂNCIA", label: "SINDICÂNCIA" },
    ];
    const optionsDescProdecimentoUniPolEsp = [
      { value: "IPL (Inquérito Policial)", label: "IPL (Inquérito Policial)" },
      {
        value: "TCO (Termo Circunstanciado de Ocorrências)",
        label: "TCO (Termo Circunstanciado de Ocorrências)",
      },
      { value: "AA (Auto de Apreensão)", label: "AA (Auto de Apreensão)" },
      {
        value: "BOC (Boletim de Ocorrência Circunstancial)",
        label: "BOC (Boletim de Ocorrência Circunstancial)",
      },
      {
        value: "AI (Alto de Investigação)",
        label: "AI (Alto de Investigação)",
      },
      { value: "ADVERTÊNCIA", label: "ADVERTÊNCIA" },
      { value: "MULTA", label: "MULTA" },
      { value: "SUSPENSÃO DAS ATIVIDADES", label: "SUSPENSÃO DAS ATIVIDADES" },
      {
        value: "CASSAÇÃO DO REGISTRO, LICENÇA OU ALVARÁ",
        label: "CASSAÇÃO DO REGISTRO, LICENÇA OU ALVARÁ",
      },
    ];
    const optionTipoPrisao = [
      { value: "PRISÃO EM FLAGRANTE", label: "PRISÃO EM FLAGRANTE" },
      { value: "PRISÃO PREVENTIVA", label: "PRISÃO PREVENTIVA" },
      { value: "PRISÃO TEMPORÁRIA", label: "PRISÃO TEMPORÁRIA" },
      { value: "APREENSÃO", label: "APREENSÃO" },
      { value: "RECAPTURADO", label: "RECAPTURADO" },
      { value: "SENTENÇA CONDENATÓRIA", label: "SENTENÇA CONDENATÓRIA" },
    ];
    const getOptionsBasedOnTipoProcedimento = (tipoProcedimento) => {
      switch (tipoProcedimento) {
        case "PROCEDIMENTOS POLICIAIS":
          return optionsDescProdecimentoPoliciais;
        case "PROCEDIMENTOS CORRECIONAIS":
          return optionsDescProdecimentoCorrecionais;
        case "PROCEDIMENTOS DE UNIDADES POLICIAIS ESPECIALIZADAS":
          return optionsDescProdecimentoUniPolEsp;
        case "PROCEDIMENTOS POLICIAIS MILITARES":
          return [];
        case "PROCEDIMENTOS ADMINISTRATIVOS":
          return [];
        default:
          return [];
      }
    };

    const optionsCoisa = [
      { value: "ARMA DE FOGO", label: "ARMA DE FOGO" },
      { value: "MUNIÇÕES", label: "MUNIÇÕES" },
      { value: "ARMA BRANCA", label: "ARMA BRANCA" },
      { value: "ENTORPECENTES", label: "ENTORPECENTES" },
      { value: "VEÍCULOS", label: "VEÍCULOS" },
      { value: "ANIMAIS", label: "ANIMAIS" },
      { value: "APARELHOS ELETRÔNICOS", label: "APARELHOS ELETRÔNICOS" },
      { value: "DINHEIRO", label: "DINHEIRO" },
      { value: "OUTRAS APREENSÕES", label: "OUTRAS APREENSÕES" },
    ];

    const optionsTipoArmaMunicao = [
      { value: "REVÓLVER", label: "REVÓLVER" },
      { value: "PISTOLA", label: "PISTOLA" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsEntorpecentes = [
      { value: "MACONHA", label: "MACONHA" },
      { value: "COCAINA", label: "COCAINA" },
      { value: "PASTA BASE", label: "PASTA BASE" },
      { value: "CRACK", label: "CRACK" },
      { value: "OXI", label: "OXI" },
      { value: "LIMÃOZINHO", label: "LIMÃOZINHO" },
      { value: "LSD", label: "LSD" },
      { value: "ECSTASY", label: "ECSTASY" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsVeiculos = [
      { value: "CARRO", label: "CARRO" },
      { value: "MOTO", label: "MOTO" },
      { value: "OUTROS", label: "OUTROS" },
    ];
    const optionsAparelhoEletronico = [
      { value: "CELULAR", label: "CELULAR" },
      {
        value: "COMPUTADOR/NOTEBOOK/TABLET",
        label: "COMPUTADOR/NOTEBOOK/TABLET",
      },
      { value: "TELEVISÃO", label: "TELEVISÃO" },
      { value: "OUTROS", label: "OUTROS" },
    ];

    const optionsCalibre = [
      { value: "22", label: "22" },
      { value: "32", label: "32" },
      { value: "38", label: "38" },
      { value: "357", label: "357" },
      { value: "765", label: "765" },
      { value: "380", label: "380" },
      { value: ".40", label: ".40" },
      { value: "6.35", label: "6.35" },
      { value: "45", label: "45" },
      { value: "9", label: "9" },
      { value: "OUTROS", label: "OUTROS" },
    ];
    const getOptionsBasedOnTipoCoisa = (tipoCoisa) => {
      switch (tipoCoisa) {
        case "ARMA DE FOGO":
          return optionsTipoArmaMunicao;
        case "MUNIÇÕES":
          return optionsTipoArmaMunicao;
        case "ENTORPECENTES":
          return optionsEntorpecentes;
        case "VEÍCULOS":
          return optionsVeiculos;
        case "APARELHOS ELETRÔNICOS":
          return optionsAparelhoEletronico;
        default:
          return [];
      }
    };
        
    const center =
      this.props.encaminhar.latitude && this.props.encaminhar.longitude
        ? [this.props.encaminhar.latitude, this.props.encaminhar.longitude]
        : [-1.4631897998991463, -48.4955653127071868];
    const logradouro = this.props.encaminhar.logradouro;
    const numero = this.props.encaminhar.numero ?? null;

    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          ...this.props.encaminhar,
          status: "Encaminhada",
          Diligencias: (this.props.encaminhar.Diligencias ?? []).map(
            (diligencia) => ({
              ...diligencia,
              Prisoes: (diligencia.Prisoes ?? []).map((prisoes) => ({
                ...prisoes,
              })),
              Apreensoes: (diligencia.Apreensoes ?? []).map((apreensoes) => ({
                ...apreensoes,
              })),
            })
          ),
          Anexos: this.props.encaminhar?.Anexos ?? [],
          Apensos: this.props.encaminhar?.Apensos ?? [],
          Historicos: this.props.encaminhar?.Historicos ?? [],
          CidadeId: this.props.encaminhar?.CidadeId ?? [],
          SetorId: this.props.encaminhar?.SetorId ?? [],
          BairroId: this.props.encaminhar?.BairroId,
          NaturezaId: this.props.encaminhar?.NaturezaId,
          UsuarioId:
            this.props.encaminhar?.UsuarioId ?? this.context.usuario.id,
          urgente: this.props.encaminhar?.urgente ?? false,
          copias: this.props.encaminhar?.copias ?? null,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.descricao) {
            errors.descricao = "Campo obrigatório";
          }
          if (!values.CanalId) {
            errors.CanalId = "Campo obrigatório";
          }
          if (!values.logradouro) {
            errors.logradouro = "Campo obrigatório";
          }
          if (!values.CidadeId) {
            errors.CidadeId = "Campo obrigatório";
          }
          if (!values.BairroId) {
            errors.BairroId = "Campo obrigatório";
          }
          if (!values.NaturezaId) {
            errors.NaturezaId = "Campo obrigatório";
          }

          if (!values.SetorId) {
            errors.SetorId = "Campo obrigatório";
          }
          if(values.status === "Arquivada" || values.status === "Devolvida ao Call Center"){
            if(!values.motivo){
              errors.motivo = "Campo obrigatório";
            }
          }

          if(!values.motivo==="Outros"){
            if(!values.outro_motivo){
              errors.outro_motivo = "Campo obrigatório";
            }
          }
          if (Object.keys(errors).length > 0) {
            this.context.addToast({
              titulo: "Erro",
              conteudo: "Verifique os campos obrigatórios e tente novamente.",
            });
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.status !== "Encaminhada") {
            delete values.OrgaoId;
            delete values.SetorId;
            delete values.copias;
          }
          if(values.motivo !== "Outros"){
            delete values.outro_motivo;
          }
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <>
              <Form> 
                <Tabs
                  defaultActiveKey="dados-basicos"
                  className="mb-3"
                  activeKey={this.state.activeKey}
                  onSelect={(key) => this.setState({ activeKey: key })} // Corrige a estrutura do setState
                >
                  {" "}
                  <Tab
                    eventKey="dados-basicos"
                    title={
                      <span style={{ fontWeight: "bold" }}>
                        DENUNCIA: {this.props.encaminhar.id}
                      </span>
                    }
                  >
                    <Row>
                      <Col lg={4}>
                        <BSForm.Label as="b">
                          Canal<span className="text-danger">*</span>
                        </BSForm.Label>
                        <ErrorMessage
                          name="CanalId"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="CanalId"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.canais}
                          value={
                            this.state.canais
                              ? this.state.canais.find(
                                  (option) => option.value === values.CanalId
                                )
                              : ""
                          }
                          onChange={(option) =>
                            setFieldValue(
                              "CanalId",
                              option ? option.value : null
                            )
                          }
                        />
                      </Col>
                      <Col lg={8}>
                        <BSForm.Label as="b">Natureza</BSForm.Label>
                        <ErrorMessage
                          name="NaturezaId"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="NaturezaId"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.naturezas}
                          value={
                            this.state.naturezas
                              ? this.state.naturezas.find(
                                  (option) => option.value === values.NaturezaId
                                )
                              : ""
                          }
                          onChange={(option) => {
                            setFieldValue(
                              "NaturezaId",
                              option ? option.value : null
                            );
                          }}
                        />
                      </Col>
                      <Col md={values.descricao_original === null ? 6 : 0}>
                        {values.descricao_original != null ? (
                          <>
                            <BSForm.Label as="b">
                              Descrição Original
                            </BSForm.Label>
                            <Field
                              readOnly
                              as="textarea"
                              name="descricao_original"
                              className="form-control"
                              value={values.descricao_original}
                              style={{ height: "200px" }}
                            />
                          </>
                        ) : null}
                      </Col>
                      <Col md={values.descricao_original === null ? 12 : 12}>
                        <BSForm.Label as="b">
                          Descrição<span className="text-danger">*</span>
                        </BSForm.Label>
                        <ErrorMessage
                          name="descricao"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <CustomEditor
                          name="descricao"
                          value={values.descricao}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                      <Col md="9">
                        <BSForm.Label as="b">Logradouro</BSForm.Label>
                        <ErrorMessage
                          name="logradouro"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="logradouro"
                          className="form-control"
                          value={values.logradouro}
                        />
                      </Col>
                      <Col md="3">
                        <BSForm.Label as="b">Número</BSForm.Label>
                        <Field
                          type="text"
                          name="numero"
                          className="form-control"
                          value={values.numero}
                        />
                      </Col>
                      <Col md="6">
                        <BSForm.Label as="b">Cidade</BSForm.Label>
                        <ErrorMessage
                          name="CidadeId"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Select
                          name="CidadeId"
                          noOptionsMessage={() => "Nada encontrado."}
                          placeholder="Pesquisar..."
                          options={this.state.cidades}
                          value={
                            this.state.cidades
                              ? this.state.cidades.find(
                                  (option) => option.value === values.CidadeId
                                )
                              : ""
                          }
                          onChange={(option) => {
                            setFieldValue(
                              "CidadeId",
                              option ? option.value : null
                            );
                            setFieldValue("BairroId", null);
                          }}
                        />
                      </Col>
                      <Col md="6">
                        <BSForm.Label as="b">Bairro</BSForm.Label>
                        <ErrorMessage
                          name="BairroId"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <CreatableSelect
                          name="BairroId"
                          noOptionsMessage={() => "Nada encontrado."}
                          onCreateOption={(label) => {
                            this.setState(
                              (state) => ({
                                bairros: [
                                  {
                                    value: "new",
                                    label: label,
                                    CidadeId: values.CidadeId,
                                  },
                                  ...state.bairros,
                                ],
                              }),
                              () => {
                                setFieldValue("BairroId", "new");
                                setFieldValue("bairro", label);
                              }
                            );
                          }}
                          placeholder="Pesquisar..."
                          formatCreateLabel={(label) => "Adicionar " + label}
                          options={this.state.bairros.filter(
                            (bairro) => bairro.CidadeId === values.CidadeId
                          )}
                          value={this.state.bairros.find(
                            (bairro) => bairro.value === values.BairroId
                          )}
                          onChange={(option) => {
                            setFieldValue("BairroId", option.value);
                            setFieldValue("bairro", option.label);
                          }}
                        />
                      </Col>
                      <Col md={12}>
                        <BSForm.Label as="b">Estabelecimento</BSForm.Label>
                        <ErrorMessage
                          name="estabelecimento"
                          component="span"
                          className="text-danger small ml-2"
                        />
                        <Field
                          type="text"
                          name="estabelecimento"
                          className="form-control"
                          value={values.estabelecimento}
                        />
                      </Col>
                      <Col lg={6}>
                        <BSForm.Label as="b">Referência</BSForm.Label>
                        <Field
                          type="text"
                          name="referencia"
                          className="form-control"
                          value={values.referencia}
                        />
                      </Col>
                      <Col lg={6}>
                        <BSForm.Label as="b">Status</BSForm.Label>
                        <Select
                          name="status"
                          options={options}
                          value={options.find(
                            (option) => option.value === values.status
                          )}
                          onChange={(selectedOption) => {
                            const selectedValue = selectedOption
                              ? selectedOption.value
                              : null;
                            setFieldValue("status", selectedValue);
                          }}
                        />
                      </Col>
                      {values.status === "Arquivada" ||
                      values.status === "Devolvida ao Call Center" ? (
                        <Col lg={6}>
                          <BSForm.Label as="b">Motivo</BSForm.Label>
                          <Select
                            name="motivo"
                            noOptionsMessage={() => "Nada encontrado."}
                            placeholder="Pesquisar..."
                            options={motivo_arquivamento}
                            value={
                              values.motivo
                                ? motivo_arquivamento.find(
                                    (motivo) => motivo.value === values.motivo
                                  )
                                : ""
                            }
                            onChange={(selectedMotivo) => {
                              const motivoValue = selectedMotivo
                                ? selectedMotivo.value
                                : null;
                              setFieldValue("motivo", motivoValue);
                            }}
                          />
                        </Col>
                      ) : null}

                      {values.motivo === "Outros"  && values.status!== "Encaminhada"? (
                        <Col md={values.motivo === "Outros" ? 12 : 0}>
                        <BSForm.Label as="b">
                          Descrição do motivo <span className="text-danger">*</span>
                        </BSForm.Label>
                        <ErrorMessage
                          name="outro_motivo"
                          component="span"
                          className="text-danger small ml-2"
                        />
                         <CustomEditor
                          name="outro_motivo"
                          value={values.outro_motivo}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                      ): null}
                      {values.status === "Encaminhada" ? (
                        <>
                          <Col md={6} className="mt-2">
                            <BSForm.Label>Unidade</BSForm.Label>
                            <ErrorMessage
                              name="SetorId"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Select
                              name="SetorId"
                              noOptionsMessage={() => "Nada encontrado."}
                              placeholder="Pesquisar..."
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              options={this.state.setores}
                              onChange={(option) => {
                                setFieldValue("SetorId", option.value);

                                // Buscar o superior do superior
                                const setorSelecionado =
                                  this.state.setores.find(
                                    (setor) => setor.value === option.value
                                  );

                                if (
                                  setorSelecionado &&
                                  setorSelecionado.SuperiorId
                                ) {
                                  const superior = this.state.setores.find(
                                    (setor) =>
                                      setor.value ===
                                      setorSelecionado.SuperiorId
                                  );

                                  if (superior && superior.SuperiorId) {
                                    const superiorDoSuperior =
                                      this.state.setores.find(
                                        (setor) =>
                                          setor.value === superior.SuperiorId
                                      );

                                    if (superiorDoSuperior) {
                                      // Adicionar automaticamente o superior do superior
                                      const alreadyExists =
                                        this.state.selectedCopias.some(
                                          (copias) =>
                                            copias.value ===
                                            superiorDoSuperior.value
                                        );

                                      if (!alreadyExists) {
                                        this.setState((prevState) => ({
                                          selectedCopias: [
                                            ...prevState.selectedCopias,
                                            {
                                              value: superiorDoSuperior.value,
                                              label: superiorDoSuperior.label,
                                            },
                                          ],
                                        }));
                                      }
                                    }
                                  }
                                }
                              }}
                            />
                          </Col>
                          <Col md={6} className="mt-2">
                            {values.status === "Encaminhada" &&
                              values.SetorId &&
                              this.state.setores.find(
                                (setor) =>
                                  setor.value === values.SetorId &&
                                  setor.SuperiorNome
                              ) && (
                                <>
                                  <BSForm.Label>Unidade Superior</BSForm.Label>
                                  <Card lg={12} className="bg-light">
                                    <Card.Body className="p-1">
                                      {
                                        this.state.setores.find(
                                          (setor) =>
                                            setor.value === values.SetorId
                                        ).SuperiorNome
                                      }
                                    </Card.Body>
                                  </Card>
                                </>
                              )}
                          </Col>

                          <Col lg={12} className="mt-2">
                            <BSForm.Label>Cópias</BSForm.Label>
                            <ErrorMessage
                              name="copias"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Select
                              name="copias"
                              isMulti
                              noOptionsMessage={() => "Nada encontrado."}
                              placeholder="Pesquisar..."
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              options={this.state.setores} // Lista completa de setores
                              value={this.state.selectedCopias} // Valores selecionados
                              onChange={(options) => {
                                // Atualizar o estado e o formulário
                                this.setState({ selectedCopias: options });
                                setFieldValue(
                                  "copias",
                                  options.map((option) => option.value)
                                );
                              }}
                            />
                          </Col>
                        </>
                      ) : null}
                      <Col
                        md={3}
                        className={
                          values.status === "Arquivada" ? "mt-4" : "mt-2"
                        }
                      >
                        <div
                          style={{
                            backgroundColor: "#ff6947",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                        >
                          <Field
                            type="checkbox"
                            name="urgente"
                            className="text-right"
                          />
                          &nbsp;&nbsp;
                          <FontAwesomeIcon icon={faExclamationCircle} />
                          <BSForm.Label as="b" style={{ marginLeft: "10px" }}>
                            Urgente
                          </BSForm.Label>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="mapa" title="Mapa">
                    {this.state.activeKey === "mapa" && ( // Certifica-se de que o componente é renderizado somente quando a aba está ativa
                      <Map
                        center={center ?? [values.latitude, values.longitude]}
                        logradouro={logradouro}
                        numero={numero}
                        policeUnits={this.state.filteredPoliceUnits}
                      />
                    )}
                  </Tab>
                  <Tab eventKey="anexos" title="Anexos">
                    <input
                      type="file"
                      multiple
                      ref={this.anexoRef}
                      className="d-none"
                      onChange={(event) => {
                        setFieldValue("Anexos", [
                          ...values.Anexos,
                          ...event.currentTarget.files,
                        ]);
                      }}
                    />
                    <Table striped size="sm" responsive className="mt-2">
                      <thead className="bg-light">
                        <tr>
                          <th>Nome</th>
                          <th style={{ width: 120, textAlign: "center" }}>
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Anexos.map((anexo, key) => (
                          <tr key={key}>
                            <td>{anexo.nome ?? ""}</td>
                            <td className="text-center">
                              {anexo.id ? (
                                <Button
                                  as="a"
                                  href={`${this.context.config.BACKEND_URL}/anexo/disquedenuncia/${anexo.id}/${anexo.uuid}`}
                                  target="_blank"
                                  download={anexo.nome}
                                  size="sm"
                                  variant="info"
                                  title="Download"
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                  ></FontAwesomeIcon>
                                </Button>
                              ) : null}
                              <Button
                                size="sm"
                                type="button"
                                variant="danger"
                                title="Excluir"
                                onClick={() =>
                                  window.confirm(
                                    "Deseja realmente excluir este arquivo?"
                                  ) &&
                                  setFieldValue(
                                    "Anexos",
                                    values.Anexos.filter((a) => a !== anexo)
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                ></FontAwesomeIcon>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4">
                            <AddAnexos
                              multiple={true}
                              onError={(file) =>
                                this.context.addToast({
                                  titulo: "Erro",
                                  conteudo:
                                    "Falha ao carregar o arquivo " + file.name,
                                })
                              }
                              onLoad={(anexos) =>
                                setFieldValue("Anexos", [
                                  ...values.Anexos,
                                  ...anexos,
                                ])
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <ErrorMessage
                              name="Anexos"
                              component="span"
                              className="text-danger small ml-2"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Tab>
                  {this.props.encaminhar?.Apensos?.length > 0 ? (
                    <Tab eventKey="apensos" title="Apenso">
                      {values.Apensos &&
                        values.Apensos.map((apenso, index) => (
                          <Row key={index} className="mb-2">
                            <Col>
                              <BSForm.Label as="b">Conteúdo</BSForm.Label>
                              <Field
                                as="textarea"
                                type="text"
                                name={`Apensos[${index}].conteudo`}
                                className="form-control"
                                disabled={true}
                                onChange={(e) =>
                                  setFieldValue(
                                    `Apensos[${index}].conteudo`,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                          </Row>
                        ))}
                    </Tab>
                  ) : null}
                  <Tab
                    eventKey="historicos"
                    title={
                      <div>
                        <span className="d-none d-lg-inline">Histórico</span>
                      </div>
                    }
                  >
                    <Table striped size="sm" responsive className="mt-2">
                      <thead className="bg-light">
                        <tr>
                          <th>Descrição</th>
                          <th>Setor</th>
                          <th>Status</th>
                          <th>Usuário</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Historicos.map((historico, key) => (
                          <tr key={key}>
                            <td>{historico.texto}</td>
                            <td>{historico.Setor.nome}</td>
                            <td style={{ width: "20%" }}>{historico.status}</td>
                            <td style={{ width: "30%" }}>
                              {historico.Usuario?.nome
                                ? historico.Usuario?.nome
                                : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <ErrorMessage
                      name="Processos"
                      component="span"
                      className="text-danger small ml-2"
                    />
                  </Tab>
                  <Tab
                    eventKey="relacionamentos"
                    title="Relacionamentos"
                    onEntering={this.handleTabSelect}
                  >
                    <Table striped responsive>
                      <thead className="bg-light">
                        <tr>
                          <th style={{ width: "16%" }}>Número da Denúncia</th>
                          <th>Descrição</th>
                          <th>Destinação</th>
                          <th>Status</th>
                          <th style={{ width: "1%" }}>Resultado</th>
                          <th style={{ width: "1%" }}>Similaridade</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.relacionamentos.map(
                          (relacionamento, key) => (
                            <React.Fragment key={key}>
                              <tr
                                onClick={() => toggleRow(key)}
                                style={{ cursor: "pointer" }}
                              >
                                <td>{relacionamento.id}</td>
                                <td>{relacionamento.descricao}</td>
                                <td>{relacionamento.setor}</td>
                                <td>{relacionamento.status}</td>
                                <td>
                                  {relacionamento.diligencia &&
                                  relacionamento.diligencia.length > 0
                                    ? relacionamento.diligencia.some(
                                        (diligencia) =>
                                          diligencia.procedente === "Sim"
                                      )
                                      ? "Procedente"
                                      : "Improcedente"
                                    : "Sem Resposta"}
                                </td>
                                <td>{`${relacionamento.porcentagem}%`}</td>
                              </tr>

                              {this.state.expandedRow === key && (
                                <tr>
                                  <td
                                    colSpan={5}
                                    style={{ backgroundColor: "#f9f9f9" }}
                                  >
                                    <div>
                                      <strong>Detalhes da Diligência:</strong>
                                      {relacionamento.diligencia &&
                                      relacionamento.diligencia.length > 0 ? (
                                        <ul>
                                          {relacionamento.diligencia.map(
                                            (diligencia, index) => (
                                              <Row key={index}>
                                                <Col md={12}>
                                                  <strong>ID:</strong>{" "}
                                                  {diligencia.id}
                                                </Col>

                                                <Col md={4}>
                                                  <strong>Averiguada ?</strong>{" "}
                                                  {diligencia.averiguada}
                                                </Col>
                                                <Col md={4}>
                                                  <strong>Motivo ?</strong>{" "}
                                                  {diligencia.motivo || "N/A"}
                                                </Col>
                                                <Col md={4}>
                                                  <strong>Outro Motivo?</strong>{" "}
                                                  {diligencia.outro_motivo ||
                                                    "N/A"}
                                                </Col>
                                                <Col md={6}>
                                                  <strong>Procedente?</strong>{" "}
                                                  {diligencia.procedente}
                                                </Col>
                                                <Col md={6}>
                                                  <strong>
                                                    Justificativa?
                                                  </strong>{" "}
                                                  {diligencia.justificativa ||
                                                    "N/A"}
                                                </Col>
                                                <Col md={4}>
                                                  <strong>Procedimento?</strong>{" "}
                                                  {diligencia.procedimento}
                                                </Col>
                                                <Col md={4}>
                                                  <strong>
                                                    Tipo de Procedimento?
                                                  </strong>{" "}
                                                  {diligencia.tipo_procedimento ||
                                                    "N/A"}
                                                </Col>
                                                <Col md={4}>
                                                  <strong>
                                                    Descrição de Procedimetno?
                                                  </strong>{" "}
                                                  {diligencia.desc_procedimento ||
                                                    "N/A"}
                                                </Col>
                                                <Col md={4}>
                                                  <strong>
                                                    Número do Procedimento?
                                                  </strong>{" "}
                                                  {diligencia.num_procedimento ||
                                                    "N/A"}
                                                </Col>
                                                <Col md={6}>
                                                  <strong>Boletim:</strong>{" "}
                                                  {diligencia.boletim}
                                                </Col>
                                                <Col md={6}>
                                                  <strong>
                                                    Númuero do Boletim:
                                                  </strong>{" "}
                                                  {diligencia.boletim_numero ||
                                                    "N/A"}
                                                </Col>

                                                <Col md={12} className="mb-4">
                                                  <strong>Observações</strong>{" "}
                                                  <CustomEditor
                                                    value={
                                                      diligencia.diligencia
                                                    }
                                                    readOnly={true}
                                                  />
                                                </Col>
                                                <hr />
                                              </Row>
                                            )
                                          )}
                                        </ul>
                                      ) : (
                                        <p>Sem diligências disponíveis.</p>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    </Table>
                  </Tab>
                  {this.props.encaminhar?.Diligencias?.length > 0 ? 
                  <Tab eventKey="diligencias" title="Diligências">
                  {values.Diligencias && 
                    values.Diligencias.map((diligencia, key) => (
                      <React.Fragment key={key}>
                        <hr />
                        <Tabs
                          className="mt-2"
                          activeKey={this.state.activeTab}
                          onSelect={(k) => this.setState({ activeTab: k })}
                        >
                          <Tab
                            eventKey="dados-basicos-diligencia"
                            title={"dados-basicos-diligencia"}
                          >
                            <Table striped bordered hover className="mt-1">
                              <thead>
                                <td>{`Diligencia Número ${key + 1}`}</td>
                                <td>
                                  {diligencia?.createdAt ? (
                                    <strong>
                                      Criado em:{" "}
                                      {moment(diligencia?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </strong>
                                  ) : null}
                                </td>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colSpan={2}>
                                    <Row className="mt-2">
                                      <Col>
                                        <BSForm.Label as="b">
                                          ULTIMA ATUALIZAÇÃO{" "}
                                          <span className="text-danger">
                                            *
                                          </span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`Diligencias[${key}].updated_at`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <FormControl
                                          disabled={true}
                                          value={`Diligencias[${key}].updated_at`}
                                        />
                                      </Col>
                                      <Col>
                                        <BSForm.Label as="b">
                                          USUÁRIO
                                          <span className="text-danger">
                                            *
                                          </span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name="usuario"
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <FormControl
                                          disabled={true}
                                          value={
                                            diligencia?.Usuario?.nome
                                              ? diligencia?.Usuario?.nome
                                              : this.context.usuario.nome
                                          }
                                        />
                                      </Col>
                                    </Row>

                                    <Row className="mt-2">
                                      <Col lg={3}>
                                        <BSForm.Label as="b">
                                          A DENÚNCIA FOI AVERIGUADA?
                                          <span className="text-danger">
                                            *
                                          </span>
                                        </BSForm.Label>
                                        <ErrorMessage
                                          name={`Diligencia[${key}].averiguada`}
                                          component="span"
                                          className="text-danger small ml-2"
                                        />
                                        <div>
                                          {optionsAveriguada.map(
                                            (averiguada, averiguadaIndex) => (
                                              <div
                                                key={averiguadaIndex}
                                                className="form-check form-check-inline"
                                              >
                                                <input
                                                  className="form-check-input"
                                                  disabled={
                                                    this.props.encaminhar
                                                      ?.Diligencias ===
                                                    undefined
                                                      ? false
                                                      : this.props.encaminhar
                                                          ?.Diligencias[key]
                                                          ?.status === "Sim"
                                                  }
                                                  type="radio"
                                                  name={`Diligencia[${key}].averiguada`}
                                                  id={`averiguada_${key}_${averiguadaIndex}`}
                                                  value={averiguada.value}
                                                  checked={
                                                    values.Diligencias[key]
                                                      .averiguada ===
                                                    averiguada.value
                                                  }
                                                  onChange={() => {
                                                    const updateaveriguada =
                                                      averiguada.value;
                                                    setFieldValue(
                                                      `Diligencias[${key}].averiguada`,
                                                      updateaveriguada
                                                    );
                                                  }}
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`averiguada${key}_${averiguadaIndex}`}
                                                >
                                                  {averiguada.label}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </Col>
                                      {diligencia?.averiguada === "Não" ? (
                                        <>
                                          <Col>
                                            <BSForm.Label as="b">
                                              MOTIVO DE NÃO AVERIGUAÇÃO
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </BSForm.Label>
                                            <ErrorMessage
                                              name={`Diligencia[${key}].motivo`}
                                              component="span"
                                              className="text-danger small ml-2"
                                            />
                                            <Select
                                              name={`Diligencias[${key}].motivo`}
                                              menuShouldBlockScroll={true}
                                              styles={{
                                                menuPortal: (base) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                              }}
                                              options={optionsNaoAveriguada}
                                              isDisabled={
                                                this.props.encaminhar
                                                  ?.Diligencias === undefined
                                                  ? false
                                                  : this.props.encaminhar
                                                      ?.Diligencias[key]
                                                      ?.status === "Sim"
                                              }
                                              value={optionsNaoAveriguada.find(
                                                (option) =>
                                                  option.value ===
                                                  diligencia.motivo
                                              )}
                                              onChange={(selectedOption) => {
                                                const selectedValue =
                                                  selectedOption
                                                    ? selectedOption.value
                                                    : null;
                                                setFieldValue(
                                                  `Diligencias[${key}].motivo`,
                                                  selectedValue
                                                );
                                              }}
                                            />
                                          </Col>
                                          {diligencia.motivo === "OUTROS" ? (
                                            <Col>
                                              <BSForm.Label as="b">
                                                Outro(s) Motivo(s)
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </BSForm.Label>
                                              <ErrorMessage
                                                name={`Diligencia[${key}].outro_motivo`}
                                                component="span"
                                                className="text-danger small ml-2"
                                              />
                                              <Field
                                                type="text"
                                                name={`Diligencias[${key}].outro_motivo`}
                                                disabled={
                                                  this.props.encaminhar
                                                    ?.Diligencias ===
                                                  undefined
                                                    ? false
                                                    : this.props.encaminhar
                                                        ?.Diligencias[key]
                                                        ?.status === "Sim"
                                                }
                                                className="form-control"
                                                onChange={(e) =>
                                                  setFieldValue(
                                                    `Diligencias[${key}].outro_motivo`,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </Col>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </Row>
                                    {diligencia?.averiguada === "Sim" ? (
                                      <Row className="mt-2">
                                        <Col lg={3}>
                                          <BSForm.Label as="b">
                                            A DENÚNCIA É PROCEDENTE?
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`Diligencia[${key}].procedente`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <div>
                                            {optionsProcedente.map(
                                              (
                                                procedente,
                                                procedenteIndex
                                              ) => (
                                                <div
                                                  key={procedenteIndex}
                                                  className="form-check form-check-inline"
                                                >
                                                  <input
                                                    className="form-check-input"
                                                    disabled={
                                                      this.props.encaminhar
                                                        ?.Diligencias ===
                                                      undefined
                                                        ? false
                                                        : this.props.encaminhar
                                                            ?.Diligencias[key]
                                                            ?.status === "Sim"
                                                    }
                                                    type="radio"
                                                    name={`Diligencia[${key}].procedente`}
                                                    id={`procedente_${key}_${procedenteIndex}`}
                                                    value={procedente.value}
                                                    checked={
                                                      values.Diligencias[key]
                                                        .procedente ===
                                                      procedente.value
                                                    }
                                                    onChange={() => {
                                                      const updateProcedente =
                                                        procedente.value;
                                                      setFieldValue(
                                                        `Diligencias[${key}].procedente`,
                                                        updateProcedente
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`procedente${key}_${procedenteIndex}`}
                                                  >
                                                    {procedente.label}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Col>
                                        {diligencia?.procedente === "Não" ? (
                                          <Col>
                                            <BSForm.Label as="b">
                                              JUSTIFICATIVA DE NÃO PROCEDENCIA
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </BSForm.Label>
                                            <ErrorMessage
                                              name={`Diligencias[${key}].justificativa`}
                                              component="span"
                                              className="text-danger small ml-2"
                                            />
                                            <Field
                                              as="textarea"
                                              type="text"
                                              name={`Diligencias[${key}].justificativa`}
                                              disabled={
                                                this.props.encaminhar
                                                  ?.Diligencias === undefined
                                                  ? false
                                                  : this.props.encaminhar
                                                      ?.Diligencias[key]
                                                      ?.status === "Sim"
                                              }
                                              className="form-control"
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `Diligencias[${key}].justificativa`,
                                                  e.target.value
                                                )
                                              }
                                              style={{ height: "200px" }}
                                            />
                                          </Col>
                                        ) : null}
                                      </Row>
                                    ) : null}

                                    {diligencia?.averiguada === "Sim" &&
                                    diligencia?.procedente === "Sim" ? (
                                      <Row className="my-2">
                                        <Col lg={3}>
                                          <BSForm.Label as="b">
                                            HOUVE PROCEDIMENTO?
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`Diligencia[${key}].procedimento`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                          <div>
                                            {optionsProcedimento.map(
                                              (
                                                procedimento,
                                                procedimentoIndex
                                              ) => (
                                                <div
                                                  key={procedimentoIndex}
                                                  className="form-check form-check-inline"
                                                >
                                                  <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    disabled={
                                                      this.props.encaminhar
                                                        ?.Diligencias ===
                                                      undefined
                                                        ? false
                                                        : this.props.encaminhar
                                                            ?.Diligencias[key]
                                                            ?.status === "Sim"
                                                    }
                                                    name={`Diligencia[${key}].procedimento`}
                                                    id={`procedimento_${key}_${procedimentoIndex}`}
                                                    value={procedimento.value}
                                                    checked={
                                                      values.Diligencias[key]
                                                        .procedimento ===
                                                      procedimento.value
                                                    }
                                                    onChange={() => {
                                                      const updateprocedimento =
                                                        procedimento.value;
                                                      setFieldValue(
                                                        `Diligencias[${key}].procedimento`,
                                                        updateprocedimento
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`procedimento${key}_${procedimentoIndex}`}
                                                  >
                                                    {procedimento.label}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Col>
                                        {diligencia?.procedimento ===
                                        "Sim" ? (
                                          <>
                                            <Col>
                                              <BSForm.Label as="b">
                                                TIPO DE PROCEDIMENTO
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </BSForm.Label>
                                              <ErrorMessage
                                                name={`Diligencia[${key}].tipo_procedimento`}
                                                component="span"
                                                className="text-danger small ml-2"
                                              />
                                              <Select
                                                name={`Diligencias[${key}].tipo_procedimento`}
                                                menuShouldBlockScroll={true}
                                                styles={{
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                }}
                                                options={
                                                  optionsTipoProcedimento
                                                }
                                                isDisabled={
                                                  this.props.encaminhar
                                                    ?.Diligencias ===
                                                  undefined
                                                    ? false
                                                    : this.props.encaminhar
                                                        ?.Diligencias[key]
                                                        ?.status === "Sim"
                                                }
                                                value={optionsTipoProcedimento.find(
                                                  (option) =>
                                                    option.value ===
                                                    diligencia.tipo_procedimento
                                                )}
                                                onChange={(
                                                  selectedOption
                                                ) => {
                                                  const selectedValue =
                                                    selectedOption
                                                      ? selectedOption.value
                                                      : null;
                                                  setFieldValue(
                                                    `Diligencias[${key}].tipo_procedimento`,
                                                    selectedValue
                                                  );
                                                }}
                                              />
                                            </Col>
                                            <Col>
                                              <BSForm.Label as="b">
                                                DESCRIÇÃO DE PROCEDIMENTO
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </BSForm.Label>
                                              <ErrorMessage
                                                name={`Diligencia[${key}].desc_procedimento`}
                                                component="span"
                                                className="text-danger small ml-2"
                                              />
                                              <Select
                                                name={`Diligencias[${key}].desc_procedimento`}
                                                menuShouldBlockScroll={true}
                                                styles={{
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                }}
                                                options={getOptionsBasedOnTipoProcedimento(
                                                  diligencia.tipo_procedimento
                                                )}
                                                isDisabled={
                                                  diligencia?.tipo_procedimento === "PROCEDIMENTOS POLICIAIS MILITARES" ||
                                                  diligencia?.tipo_procedimento === "PROCEDIMENTOS ADMINISTRATIVOS" ||
                                                  (this.props.encaminhar?.Diligencias
                                                     && this.props.encaminhar?.Diligencias[key]?.status === "Sim")
                                                }
                                                value={this.props.encaminhar?.Diligencias && this.props.encaminhar.Diligencias[key]
                                                  ? getOptionsBasedOnTipoProcedimento(
                                                      this.props.encaminhar.Diligencias[key]?.tipo_procedimento
                                                    ).find((option) => option.value === diligencia.desc_procedimento)
                                                  : null}
                                                
                                                onChange={(
                                                  selectedOption
                                                ) => {
                                                  const selectedValue =
                                                    selectedOption
                                                      ? selectedOption.value
                                                      : null;
                                                  setFieldValue(
                                                    `Diligencias[${key}].desc_procedimento`,
                                                    selectedValue
                                                  );
                                                }}
                                              />
                                            </Col>

                                            <Col>
                                              <BSForm.Label as="b">
                                                NUMERO DO PROCEDIMENTO
                                                {!diligencia.tipo_procedimento ===
                                                  "PROCEDIMENTOS POLICIAIS MILITARES" ||
                                                  !diligencia.tipo_procedimento ===
                                                    "PROCEDIMENTOS ADMINISTRATIVOS" ||
                                                diligencia.tipo_procedimento ===
                                                  "PROCEDIMENTOS POLICIAIS" ? (
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                ) : null}
                                              </BSForm.Label>
                                              <ErrorMessage
                                                name={`Diligencia[${key}].num_procedimento`}
                                                component="span"
                                                className="text-danger small ml-2"
                                              />
                                              <Field
                                                type="text"
                                                name={`Diligencias[${key}].num_procedimento`}
                                                className="form-control"
                                                disabled={
                                                  (this.props.encaminhar?.Diligencias
                                                    && this.props.encaminhar?.Diligencias[key]?.status === "Sim")
                                                }
                                                onChange={(e) =>
                                                  setFieldValue(
                                                    `Diligencias[${key}].num_procedimento`,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </Col>
                                          </>
                                        ) : null}
                                      </Row>
                                    ) : null}
                                    {diligencia?.procedimento === "Não" &&
                                    diligencia.averiguada === "Sim" &&
                                    diligencia.procedente === "Sim" ? (
                                      <Row>
                                        <Col lg={3}>
                                          <BSForm.Label as="b">
                                            HOUVE BOLETIM DE OCORRÊNCIA?
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </BSForm.Label>
                                          <ErrorMessage
                                            name={`Diligencia[${key}].boletim`}
                                            component="span"
                                            className="text-danger small ml-2"
                                          />
                                        
                                        <div>
                                          {optionsBoletimOcorrencia.map(
                                            (boletim, boletimIndex) => (
                                              <div
                                                key={boletimIndex}
                                                className="form-check form-check-inline"
                                              >
                                                <input
                                                  className="form-check-input"
                                                  type="radio"
                                                  disabled={
                                                    this.props.encaminhar
                                                      ?.Diligencias ===
                                                    undefined
                                                      ? false
                                                      : this.props.encaminhar
                                                          ?.Diligencias[key]
                                                          ?.status === "Sim"
                                                  }
                                                  name={`Diligencia[${key}].boletim`}
                                                  id={`boletim_${key}_${boletimIndex}`}
                                                  value={boletim.value}
                                                  checked={
                                                    values.Diligencias[key]
                                                      .boletim ===
                                                    boletim.value
                                                  }
                                                  
                                                  onChange={() => {
                                                    const updateboletim =
                                                      boletim.value;
                                                    setFieldValue(
                                                      `Diligencias[${key}].boletim`,
                                                      updateboletim
                                                    );
                                                  }}
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`boletim${key}_${boletimIndex}`}
                                                >
                                                  {boletim.label}
                                                </label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                        </Col>
                                        {diligencia?.boletim === "Sim" ? (
                                          <Col>
                                            <BSForm.Label as="b">
                                              NUMERO DO BOLETIM
                                              {diligencia?.boletim ===
                                              "Sim" ? (
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              ) : null}
                                            </BSForm.Label>
                                            <ErrorMessage
                                              name={`Diligencia[${key}].boletim_numero`}
                                              component="span"
                                              className="text-danger small ml-2"
                                            />
                                            <Field
                                              type="text"
                                              name={`Diligencias[${key}].boletim_numero`}
                                              className="form-control"
                                              disabled={
                                                (this.props.encaminhar?.Diligencias
                                                  && this.props.encaminhar?.Diligencias[key]?.status === "Sim")
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `Diligencias[${key}].boletim_numero`,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Col>
                                        ) : null}
                                      </Row>
                                    ) : null}

                                    <Row className="mt-2">
                                      <Col>
                                        <BSForm.Label as="b">
                                          OBSERVAÇÕES
                                          {diligencia?.motivo ===
                                              "DENÚNCIA PERTENCE A OUTRA CIRCUNSCRIÇÃO POLICIAL" ? (
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              ) : null}
                                        </BSForm.Label>

                                        <CustomEditor
                                          name={`Diligencias[${key}].diligencia`} // Define o caminho correto do campo
                                          value={
                                            values.Diligencias[key].diligencia
                                          } // Valor específico da diligência atual
                                          setFieldValue={setFieldValue}
                                          rreadOnly={
                                            (this.props.encaminhar?.Diligencias?.[key]?.status ?? "Não") === "Sim"
                                          }
                                          
                                        />
                                      </Col>
                                    </Row>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Tab>
                          <Tab eventKey={`apreensoes`} title="Apreensões">
                            {values.Diligencias[key]?.Apreensoes?.length >
                            0 ? (
                              values.Diligencias[key]?.Apreensoes?.map(
                                (apreensoes, apreensoesIndex) => (
                                  <Table striped bordered hover>
                                    <thead>
                                      <td>{`Apreensão Número ${
                                        apreensoesIndex + 1
                                      }`}</td>
                                      <td
                                        style={{
                                          width: "1%",
                                          textAlign: "center",
                                        }}
                                      >
                                        Excluir
                                      </td>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <Row
                                            key={apreensoesIndex}
                                            className="mt-2"
                                          >
                                            <Col lg={6}>
                                              <BSForm.Label as="b">
                                                OBJETO APREENDIDO{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </BSForm.Label>
                                              <ErrorMessage
                                                name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].coisa`}
                                                component="span"
                                                className="text-danger small ml-2"
                                              />
                                              <Select
                                                name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].coisa`}
                                                menuShouldBlockScroll={true}
                                                styles={{
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                }}
                                                options={optionsCoisa}
                                                isDisabled={
                                                  this.props.encaminhar
                                                    ?.Diligencias ===
                                                  undefined
                                                    ? false
                                                    : this.props.encaminhar
                                                        ?.Diligencias[key]
                                                        ?.status === "Sim"
                                                }
                                                value={optionsCoisa.find(
                                                  (option) =>
                                                    option.value ===
                                                    apreensoes.coisa
                                                )}
                                                onChange={(
                                                  selectedOption
                                                ) => {
                                                  const selectedValue =
                                                    selectedOption
                                                      ? selectedOption.value
                                                      : null;
                                                  setFieldValue(
                                                    `Diligencias[${key}].Apreensoes[${apreensoesIndex}.coisa]`,
                                                    selectedValue
                                                  );
                                                }}
                                              />
                                            </Col>
                                            {apreensoes.coisa ===
                                              "ARMA DE FOGO" ||
                                            apreensoes.coisa === "MUNIÇÕES" ||
                                            apreensoes.coisa ===
                                              "ENTORPECENTES" ||
                                            apreensoes.coisa === "VEÍCULOS" ||
                                            apreensoes.coisa ===
                                              "APARELHOS ELETRÔNICOS" ? (
                                              <>
                                                <Col lg={6}>
                                                  <BSForm.Label as="b">
                                                    TIPO DE OBJETO APREENDIDO{" "}
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                  </BSForm.Label>
                                                  <ErrorMessage
                                                    name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].tipo`}
                                                    component="span"
                                                    className="text-danger small ml-2"
                                                  />
                                                  <Select
                                                    name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].tipo`}
                                                    menuShouldBlockScroll={
                                                      true
                                                    }
                                                    styles={{
                                                      menuPortal: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                      }),
                                                    }}
                                                    options={getOptionsBasedOnTipoCoisa(
                                                      apreensoes.coisa
                                                    )}
                                                    isDisabled={
                                                      this.props.encaminhar
                                                        ?.Diligencias ===
                                                      undefined
                                                        ? false
                                                        : this.props.encaminhar
                                                            ?.Diligencias[key]
                                                            ?.status === "Sim"
                                                    }
                                                    value={getOptionsBasedOnTipoCoisa(
                                                      this.props.encaminhar
                                                        ?.Diligencias[key]
                                                        ?.Apreensoes[
                                                        apreensoesIndex
                                                      ]?.coisa
                                                    ).find(
                                                      (option) =>
                                                        option.value ===
                                                        apreensoes.tipo
                                                    )}
                                                    onChange={(
                                                      selectedOption
                                                    ) => {
                                                      const selectedValue =
                                                        selectedOption
                                                          ? selectedOption.value
                                                          : null;
                                                      setFieldValue(
                                                        `Diligencias[${key}].Apreensoes[${apreensoesIndex}].tipo`,
                                                        selectedValue
                                                      );
                                                    }}
                                                  />
                                                </Col>
                                              </>
                                            ) : null}
                                            {(apreensoes.coisa ===
                                              "ARMA DE FOGO" ||
                                              apreensoes.coisa ===
                                                "MUNIÇÕES") &&
                                            (apreensoes.tipo === "REVÓLVER" ||
                                              apreensoes.tipo ===
                                                "PISTOLA") ? (
                                              <Col lg={6}>
                                                <BSForm.Label as="b">
                                                  CALIBRE{" "}
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </BSForm.Label>
                                                <ErrorMessage
                                                  name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].calibre`}
                                                  component="span"
                                                  className="text-danger small ml-2"
                                                />
                                                <Select
                                                  name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}.calibre]`}
                                                  menuShouldBlockScroll={true}
                                                  styles={{
                                                    menuPortal: (base) => ({
                                                      ...base,
                                                      zIndex: 9999,
                                                    }),
                                                  }}
                                                  options={optionsCalibre}
                                                  isDisabled={
                                                    this.props.encaminhar
                                                      ?.Diligencias ===
                                                    undefined
                                                      ? false
                                                      : this.props.encaminhar
                                                          ?.Diligencias[key]
                                                          ?.status === "Sim"
                                                  }
                                                  value={optionsCalibre.find(
                                                    (option) =>
                                                      option.value ===
                                                      apreensoes.calibre
                                                  )}
                                                  onChange={(
                                                    selectedOption
                                                  ) => {
                                                    const selectedValue =
                                                      selectedOption
                                                        ? selectedOption.value
                                                        : null;
                                                    setFieldValue(
                                                      `Diligencias[${key}].Apreensoes[${apreensoesIndex}.calibre]`,
                                                      selectedValue
                                                    );
                                                  }}
                                                />
                                              </Col>
                                            ) : null}
                                            {apreensoes.coisa !==
                                            "DINHEIRO" ? (
                                              <Col lg={6}>
                                                <BSForm.Label as="b">
                                                  QUANTIDADE{" "}
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </BSForm.Label>
                                                <ErrorMessage
                                                  name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].quantidade`}
                                                  component="span"
                                                  className="text-danger small ml-2"
                                                />
                                                <Field
                                                  type="number"
                                                  name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].quantidade`}
                                                  disabled={
                                                    this.props.encaminhar
                                                      ?.Diligencias ===
                                                    undefined
                                                      ? false
                                                      : this.props.encaminhar
                                                          ?.Diligencias[key]
                                                          ?.status === "Sim"
                                                  }
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `Diligencias[${key}].Apreensoes[${apreensoesIndex}].quantidade`,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Col>
                                            ) : null}
                                            {apreensoes.coisa ===
                                            "DINHEIRO" ? (
                                              <Col lg={6}>
                                                <BSForm.Label as="b">
                                                  VALOR{" "}
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </BSForm.Label>
                                                <ErrorMessage
                                                  name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].valor`}
                                                  component="span"
                                                  className="text-danger small ml-2"
                                                />
                                                <Field
                                                  type="text"
                                                  name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].valor`}
                                                  disabled={
                                                    this.props.encaminhar
                                                      ?.Diligencias ===
                                                    undefined
                                                      ? false
                                                      : this.props.encaminhar
                                                          ?.Diligencias[key]
                                                          ?.status === "Sim"
                                                  }
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `Diligencias[${key}].Apreensoes[${apreensoesIndex}].valor`,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Col>
                                            ) : null}

                                            {apreensoes.coisa !==
                                              "DINHEIRO" &&
                                            (apreensoes.coisa === "ANIMAIS" ||
                                              apreensoes.coisa ===
                                                "OUTRAS APREENSÕES" ||
                                              apreensoes.calibre ===
                                                "OUTROS" ||
                                              apreensoes.coisa ===
                                                "APARELHOS ELETRÔNICOS" ||
                                              apreensoes.tipo === "OUTROS" ||
                                              apreensoes.coisa ===
                                                "ARMA BRANCA") ? (
                                              <Col lg={6}>
                                                <BSForm.Label as="b">
                                                  DESCRIÇÃO{" "}
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </BSForm.Label>
                                                <ErrorMessage
                                                  name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].descricao`}
                                                  component="span"
                                                  className="text-danger small ml-2"
                                                />
                                                <Field
                                                  type="text"
                                                  name={`Diligencias[${key}].Apreensoes[${apreensoesIndex}].descricao`}
                                                  disabled={
                                                    this.props.encaminhar
                                                      ?.Diligencias ===
                                                    undefined
                                                      ? false
                                                      : this.props.encaminhar
                                                          ?.Diligencias[key]
                                                          ?.status === "Sim"
                                                  }
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `Diligencias[${key}].Apreensoes[${apreensoesIndex}].descricao`,
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Col>
                                            ) : null}
                                          </Row>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                )
                              )
                            ) : (
                              <h5 className="text-center mt-3">
                                Nenhuma apreensão criada
                              </h5>
                            )}
                          </Tab>
                          <Tab eventKey={`prisoes`} title="Prisões">
                            {values.Diligencias[key]?.Prisoes?.length > 0 ? (
                              values.Diligencias[key]?.Prisoes?.map(
                                (prisao, prisaoIndex) => (
                                  <Table striped bordered hover>
                                    <thead>
                                      <tr>
                                        <td>{`Prisão Número ${
                                          prisaoIndex + 1
                                        }`}</td>
                                        <td
                                          style={{
                                            width: "1%",
                                            textAlign: "center",
                                          }}
                                        >
                                          Excluir
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <Row
                                            key={prisaoIndex}
                                            className="my=3"
                                          >
                                            <Col>
                                              <BSForm.Label as="b">
                                                TIPO DE PRISÃO OU APREENSÃO
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </BSForm.Label>
                                              <ErrorMessage
                                                name={`Diligencias[${key}].Prisoes[${prisaoIndex}].tipo`}
                                                component="span"
                                                className="text-danger small ml-2"
                                              />
                                              <Select
                                                name={`Diligencias[${key}].Prisoes[${prisaoIndex}].tipo`}
                                                menuShouldBlockScroll={true}
                                                styles={{
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }),
                                                }}
                                                options={optionTipoPrisao}
                                                isDisabled={
                                                  this.props.encaminhar
                                                    ?.Diligencias ===
                                                  undefined
                                                    ? false
                                                    : this.props.encaminhar
                                                        ?.Diligencias[key]
                                                        ?.status === "Sim"
                                                }
                                                value={optionTipoPrisao.find(
                                                  (option) =>
                                                    option.value ===
                                                    prisao.tipo
                                                )}
                                                onChange={(
                                                  selectedOption
                                                ) => {
                                                  const selectedValue =
                                                    selectedOption
                                                      ? selectedOption.value
                                                      : null;
                                                  setFieldValue(
                                                    `Diligencias[${key}].Prisoes[${prisaoIndex}].tipo`,
                                                    selectedValue
                                                  );
                                                }}
                                              />
                                            </Col>
                                            <Col>
                                              <BSForm.Label as="b">
                                                NOME COMPLETO DO PRESO
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </BSForm.Label>
                                              <ErrorMessage
                                                name={`Diligencias[${key}].Prisoes[${prisaoIndex}].nome_preso`}
                                                component="span"
                                                className="text-danger small ml-2"
                                              />
                                              <Field
                                                name={`Diligencias[${key}].Prisoes[${prisaoIndex}].nome_preso`}
                                                type="text"
                                                disabled={
                                                  this.props.encaminhar
                                                    ?.Diligencias[key]
                                                    ?.status === "Sim"
                                                }
                                                className="form-control"
                                                onChange={(e) =>
                                                  setFieldValue(
                                                    `Diligencias[${key}].Prisoes[${prisaoIndex}].nome_preso`,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </td>
                                        
                                      </tr>
                                    </tbody>
                                  </Table>
                                )
                              )
                            ) : (
                              <h5 className="text-center mt-3">
                                Nenhuma prisão criada
                              </h5>
                            )}
                          </Tab>
                        </Tabs>
                      </React.Fragment>
                    ))
                  }
                </Tab>
                  
                  : null}
                  
                </Tabs>
                <FormGroup className="text-right">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}
export default EncaminharDenunciaForm;
